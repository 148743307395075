import styled from 'styled-components'
import { Section } from 'components'
import { Investor } from 'models'
import { LocationSettingsTableReadOnly } from './LocationSettingsTableReadOnly'

type LocationSettingsCurrentListProps = {
  investor: Investor
}

const InactiveSettingsWrapper = styled.div`
  opacity: 0.5;
`

export const LocationSettingsCurrentList = ({ investor }: LocationSettingsCurrentListProps) => {
  return (
    <>
      <Section>
        <LocationSettingsTableReadOnly investor={investor} active={true} />
      </Section>

      <Section>
        <InactiveSettingsWrapper>
          <LocationSettingsTableReadOnly active={false} investor={investor} />
        </InactiveSettingsWrapper>
      </Section>
    </>
  )
}
