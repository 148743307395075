import React, { useEffect, useState } from 'react'
import { useFeatureFlags } from 'hooks/flagsmith'
import { Button } from '../../../../@foundation/components'
import { TColumn } from '../../../../@foundation/components/Table'
import styles from '../../../../@foundation/components/Table/Table.module.scss'
import {
  CheckBoxArray,
  Column,
  Columns,
  MenuSection,
  Reorderable,
  Section,
  SectionFooter
} from '../../../../components'
import { titleize, toSnakeCase } from '../../../../utils'
import { BBYSLead } from '../../types'

type ColumnsControlProps = {
  columns: TColumn<BBYSLead>[]
  defaultColumns?: TColumn<BBYSLead>[]
  setColumns: (columns: TColumn<BBYSLead>[]) => void
  setIsColumnsControlOpen: (isOpen: boolean) => void
}

export function ColumnsControl({
  defaultColumns,
  columns,
  setColumns,
  setIsColumnsControlOpen
}: ColumnsControlProps) {
  const [updatedColumns, setUpdatedColumns] = useState(columns)
  const [hasUpdatedColumns, setHasUpdatedColumns] = useState(false)

  useEffect(() => {
    setUpdatedColumns(columns)
  }, [columns])

  const { repaperExtensionTracking } = useFeatureFlags(['repaper-extension-tracking'])

  const columnGroups = [
    'Lead Details',
    'Departing Residence',
    'Equity Unlock',
    repaperExtensionTracking.enabled ? 'Repaper' : null,
    'Dates & Milestones'
  ].filter(col => col !== null)

  return (
    <Section className={styles.section}>
      <Columns hasFixedHeight>
        {columnGroups.map(group => (
          <Column className={styles.column} key={group}>
            <MenuSection title={titleize(toSnakeCase(group))} className={styles.menuSection}>
              <CheckBoxArray
                className={styles.checkboxArray}
                // @ts-ignore
                options={defaultColumns
                  // @ts-ignore
                  .filter(column => column.group === group)
                  .map(column => column.label)}
                value={updatedColumns.filter(col => col.display).map(column => column.label)}
                onChange={cols => {
                  const newCols = defaultColumns.map(col => ({
                    ...col,
                    display: cols.includes(col.label)
                  }))

                  setUpdatedColumns(newCols)
                  setHasUpdatedColumns(true)
                }}
              />
            </MenuSection>
          </Column>
        ))}
        <Column className={styles.column}>
          <MenuSection title="Drag to order columns" className={styles.menuSection}>
            <Reorderable.Provider>
              <Reorderable.List
                className={styles.checkboxArray}
                options={updatedColumns
                  .filter(column => (column.label && column.display) || column.frozen)
                  .map(column => ({
                    name: column.label,
                    value: column.label,
                    isReorderable: !column.frozen,
                    toOption: () => new Option(column.label) // this doesn't seem to be needed, but here because of what Reorderable.List interface requires
                  }))}
                onChange={cols => {
                  if (Array.isArray(cols) && cols.length) {
                    const newCols = cols.map(col => updatedColumns.find(c => c.label === col.name))
                    setUpdatedColumns(newCols)
                    setHasUpdatedColumns(true)
                  }
                }}
              />
            </Reorderable.Provider>
          </MenuSection>
        </Column>
      </Columns>

      <SectionFooter className={styles.footer}>
        <Button
          variant="ghost"
          onClick={() => {
            setHasUpdatedColumns(true)
            setUpdatedColumns(columns.map(c => ({ ...c, display: false })))
          }}
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setHasUpdatedColumns(false)
            setIsColumnsControlOpen(false)
            setColumns(updatedColumns)
          }}
          isDisabled={!hasUpdatedColumns}
        >
          Apply
        </Button>
      </SectionFooter>
    </Section>
  )
}

export default ColumnsControl
