import { useRef, useState } from 'react'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { parseISO } from 'date-fns'
import { useUpdateEffect } from 'react-use'
import { Box } from '@foundation/components'
import { Calendar, CalendarDatetime, Icon, TCalendarProps, TextInput } from 'components'
import { cx, formatDateOrDateTime } from 'utils'
import styles from './DateInput.module.scss'
import { useDateInput } from './useDateInput'

export type DateInputProps = {
  calendarProps?: Partial<TCalendarProps>
  className?: string
  id?: string
  includeTime?: boolean
  maxDate?: Date
  minDate?: Date
  onChange: (val: string) => void
  onClose?: () => void
  startOpen?: boolean
  value: string | undefined
}

const setCurrentTime = (selectedValue: Date) => {
  const now = new Date()
  const old = selectedValue.setHours(now.getHours(), now.getMinutes())

  return new Date(old)
}

export const DateTimeInput = ({ onChange, ...props }: Omit<DateInputProps, 'includeTime'>) => {
  return <DateInput onChange={onChange} includeTime={true} {...props} />
}

export const DateInput = ({
  id,
  value,
  className,
  onChange,
  onClose,
  startOpen = false,
  includeTime = false,
  minDate,
  maxDate,
  calendarProps = {}
}: DateInputProps) => {
  const [isOpen, setIsOpen] = useState(startOpen)
  const onCloseRef = useRef(onClose)
  onCloseRef.current = onClose

  const { triggerProps, floatingProps, context } = useDateInput({
    isOpen,
    onOpenChange: setIsOpen
  })

  useUpdateEffect(() => {
    if (!isOpen && onCloseRef.current) {
      onCloseRef.current()
    }
  }, [isOpen])

  const formattedValue = formatDateOrDateTime(value, { includeTime })

  const handleChangeCalendar = (selectedValue: Date) => {
    const dateValue = includeTime
      ? selectedValue.toISOString()
      : selectedValue.toISOString().split('T')[0]
    onChange?.(dateValue)
    setIsOpen(false)
  }

  const handleChangeCalendarDatetime = (selectedValue: Date) => {
    if (selectedValue && !value) {
      onChange?.(setCurrentTime(selectedValue).toISOString())
    } else {
      onChange?.(selectedValue.toISOString())
    }
    setIsOpen(false)
  }

  return (
    <>
      <div
        data-testid="dateInputTrigger"
        className={cx(className, styles.dateInput)}
        {...triggerProps}
      >
        <TextInput id={id} readOnly value={formattedValue} />
        <Icon.Close data-testid="closeIcon" onClick={() => onChange('')} />
      </div>
      <FloatingPortal id="datepicker-portal">
        {isOpen && (
          <FloatingFocusManager context={context}>
            <Box
              data-testid="datepickerPopup"
              zIndex="dropdown"
              boxShadow="lg"
              borderRadius="base"
              backgroundColor="white"
              className={includeTime ? styles.dateTimeCoaster : styles.dateCoaster}
              {...floatingProps}
            >
              {includeTime ? (
                <CalendarDatetime
                  value={value ? parseISO(value) : undefined}
                  onChange={handleChangeCalendarDatetime}
                />
              ) : (
                <Calendar
                  value={value ? parseISO(value) : undefined}
                  onChange={handleChangeCalendar}
                  minDate={minDate}
                  maxDate={maxDate}
                  {...calendarProps}
                />
              )}
            </Box>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}
