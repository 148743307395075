// @ts-strict
import { Box, Flex } from '@foundation/components'
import { Contents } from 'components'
import { Divider } from 'components/Divider'
import { Investor } from 'models'
import InvestorBillingStatus from './InvestorBillingStatus'
import { LocationSettingsCurrentList } from './LocationSettings'

type Props = {
  investor: Investor
}

export const InvestorBilling = ({ investor }: Props) => {
  return (
    <Contents title="Billing Type">
      <Box
        borderStyle="solid"
        borderColor="neutral5"
        borderWidth="thin"
        borderRadius="xl"
        padding="$8"
        width="485px"
      >
        <Box backgroundColor="neutral2" padding="$8" borderRadius="xl">
          <Flex flexDirection="column" gap="$7">
            <InvestorBillingStatus investor={investor} />
          </Flex>
        </Box>
        <Box paddingTop="$6" paddingBottom="$6">
          <Divider />
        </Box>
        <LocationSettingsCurrentList investor={investor} />
      </Box>
    </Contents>
  )
}
