import {
  ExternalLink,
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditDollarsInput,
  InlineEditEmailInput,
  InlineEditPercentageInput,
  InlineEditPhoneInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import { useFeatureFlags } from 'hooks'
import { hlcsNotAttachedReasonOptions, useCapitalPartners, yesOrNoOptions } from 'lookups'
import { useQuery } from '../../../../hooks'
import { getLead } from '../../../../models'
import { getHapiRequestParams } from '../../../../schema'
import { getLeadsUrl } from '../../../../services/getUrl'
import { deserializeAsync, getRequest } from '../../../../utils'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import styles from './GlobalStyles.module.scss'
import { getBBYSLeadInputProps } from './utils'

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLead' | 'updateLead'>

const fetchBBYSActualLoanAmount = async lead => {
  const leadResponseShape = {
    lead: [
      {
        'bbys_provider_lead:provider_lead ': [
          {
            'providable:bbys_lead': ['actual_loan_amount', 'approval_type']
          }
        ]
      }
    ]
  }

  const searchParams = getHapiRequestParams(leadResponseShape as any)
  const url = getLeadsUrl({ id: lead.id, searchParams })
  const primaryResponse = await getRequest(url)

  const result = await deserializeAsync(primaryResponse.data, {
    transform: getLead
  })

  return result?.bbysProviderLead?.providable?.actualLoanAmount
}

export const DRPropertyListingOrSaleInformationSection = ({
  lead,
  updateBBYSLead,
  updateLead
}: Props) => {
  const { capitalPartnersOptions } = useCapitalPartners()
  const { updateCapitalPartner } = useLeadContext()
  const providerLead = lead.bbysProviderLead!
  const bbysLead = providerLead.bbysLead
  const capitalPartner = providerLead.providerLeadCapitalPartner?.capitalPartner
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)
  const { value: link } = getProps('realEstatePortalLink')
  const { fieldDataAutomation, payoffDemandUpdates, repaperExtensionTracking } = useFeatureFlags([
    'field-data-automation',
    'payoff-demand-updates',
    'repaper-extension-tracking'
  ])
  const { data, isLoading } = useQuery(
    ['ACTUAL_LOAN_AMOUNT', lead.id],
    () => fetchBBYSActualLoanAmount(lead),
    {
      refetchOnWindowFocus: false,
      enabled: true
    }
  )

  return (
    <Sections title="Listing/sale information (Departing Residence)">
      <Section>
        <FormField title="Title company" inline>
          <InlineEditTextInput {...getProps('departingResidenceTitleCompany')} />
        </FormField>
        <FormField title="Title company email address" inline>
          <InlineEditEmailInput {...getProps('departingResidenceTitleCompanyEmail')} />
        </FormField>
        <FormField title="Additional title company email address" inline>
          <InlineEditEmailInput {...getProps('departingResidenceTitleCompanyAdditionalEmail')} />
        </FormField>
        <FormField title="Title company phone number" inline>
          <InlineEditPhoneInput {...getProps('departingResidenceTitleCompanyPhone')} />
        </FormField>
        <FormField title="Listing plan call invite date" inline>
          <InlineEditDateInput {...getProps('listingPlanCallInviteDate')} />
        </FormField>
        <FormField title="Listing call follow up date" inline>
          <InlineEditDateInput {...getProps('listingCallFollowUpDate')} />
        </FormField>
        <FormField title="Original list price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceOriginalListPrice')} />
        </FormField>
        {fieldDataAutomation.enabled && (
          <>
            <FormField title="HLCS Attached" inline>
              <InlineEditSelect options={yesOrNoOptions} {...getProps('hlcsAttached')} />
            </FormField>
            <FormField title="Reason for no HLCS Attach" inline>
              <InlineEditSelect
                options={hlcsNotAttachedReasonOptions}
                {...getProps('hlcsNotAttachedReason')}
              />
            </FormField>
            <FormField title="Expected program fee" inline>
              <InlineEditPercentageInput {...getProps('expectedProgramFeeRate')} />
            </FormField>
            <FormField title="Actual program fee" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFee')} readOnly />
            </FormField>
            <FormField title="Program fee surcharge" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFeeSurcharge')} />
            </FormField>
            <FormField title="Program fee discount" inline>
              <InlineEditDollarsAndCentsInput {...getProps('programFeeDiscount')} />
            </FormField>
            {payoffDemandUpdates.enabled && (
              <FormField title="Late Listing Fees" inline>
                <InlineEditDollarsAndCentsInput {...getProps('lateListingFee')} />
              </FormField>
            )}
            <FormField title="Actual loan payoff" inline>
              {isLoading ? (
                <InlineEditTextInput value="Loading..." readOnly />
              ) : (
                <InlineEditDollarsAndCentsInput value={data} readOnly />
              )}
            </FormField>
            {repaperExtensionTracking.enabled && (
              <>
                <FormField title="HL Purchased DR Valuation Amount" inline>
                  <InlineEditDollarsAndCentsInput {...getProps('hlPurchasedDrValuationAmount')} />
                </FormField>
                <FormField title="HL Purchased DR Valuation Date" inline>
                  <InlineEditDateInput {...getProps('hlPurchasedDrValuationDate')} />
                </FormField>
                <FormField title="HL Refresh Valuation Amount" inline>
                  <InlineEditDollarsAndCentsInput {...getProps('hlRefreshValuationAmount')} />
                </FormField>
                <FormField title="HL Refresh Valuation Date" inline>
                  <InlineEditDateInput {...getProps('hlRefreshValuationDate')} />
                </FormField>
              </>
            )}
          </>
        )}
      </Section>
      <Section>
        <FormField title="Current list price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceCurrentListPrice')} />
        </FormField>
        <FormField title="Final sale price" inline>
          <InlineEditDollarsInput {...getProps('departingResidenceFinalSalePrice')} />
        </FormField>
        {payoffDemandUpdates.enabled && (
          <FormField title="Closing Management Fee" inline>
            <InlineEditDollarsAndCentsInput {...getProps('administrationFee')} />
          </FormField>
        )}
        <FormField title="Real estate portal link" inline>
          {link ? (
            <div className={styles.row}>
              <InlineEditTextInput {...getProps('realEstatePortalLink')} />
              <ExternalLink href={link}>Link</ExternalLink>
            </div>
          ) : (
            <InlineEditTextInput {...getProps('realEstatePortalLink')} />
          )}
        </FormField>
        {fieldDataAutomation.enabled && (
          <FormField title="Target purchase COE date" inline>
            <InlineEditDateInput {...getProps('departingPropertyTargetPurchaseCoeDate')} />
          </FormField>
        )}
        <FormField title="Actual loan payoff - HL" inline>
          <InlineEditDollarsAndCentsInput {...getProps('dpActualLoanPayoff')} />
        </FormField>
        <FormField title="Payoff Requested Date" inline>
          <InlineEditDateInput {...getProps('payoffRequestedDate')} />
        </FormField>
        <FormField title="Direct Payoff Requested Date" inline>
          <InlineEditDateInput {...getProps('directPayoffRequestedAt')} />
        </FormField>
        <FormField title="Direct Payoff Sale Price" inline>
          <InlineEditDollarsAndCentsInput {...getProps('directPayoffSalePrice')} />
        </FormField>
        {!repaperExtensionTracking.enabled && (
          <FormField title="BBYS extension date" inline>
            <InlineEditDateInput {...getProps('programExtensionDate')} />
          </FormField>
        )}
        <FormField title="DR Purchase Credit Facility" inline>
          <InlineEditSelect
            options={capitalPartnersOptions}
            value={capitalPartner?.id}
            onChange={value => updateCapitalPartner({ providerLead, capitalPartnerId: value })}
          />
        </FormField>
        <FormField title="HL DR Purchase Funding Amount" inline>
          <InlineEditDollarsInput {...getProps('hlDrPurchaseFundingAmount')} />
        </FormField>
        <FormField title="HL DR Purchase Price" inline>
          <InlineEditDollarsInput {...getProps('hlDrPurchasePrice')} />
        </FormField>
        <FormField title="Title company (HL Purchase)" inline>
          <InlineEditTextInput {...getProps('hlPurchaseTitleCompany')} />
        </FormField>
        <FormField title="Title company email address (HL Purchase)" inline>
          <InlineEditEmailInput {...getProps('hlPurchaseTitleCompanyEmail')} />
        </FormField>
        <FormField title="Title company Additional email address (HL Purchase)" inline>
          <InlineEditEmailInput {...getProps('hlPurchaseTitleCompanyAdditionalEmail')} />
        </FormField>
        <FormField title="Title company phone number (HL Purchase)" inline>
          <InlineEditPhoneInput {...getProps('hlPurchaseTitleCompanyPhone')} />
        </FormField>
      </Section>
    </Sections>
  )
}
