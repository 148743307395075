// @ts-strict

import {
  Block,
  ExternalLink,
  Field,
  FormField,
  InlineEditMoneyInput,
  InlineEditMultiSelect,
  InlineEditSelect,
  InlineEditTextInput,
  List,
  MapBox,
  OnScreen,
  Section,
  Sections,
  ViewMore
} from 'components'
import { TransactionTeamMembersCSV } from 'content/TransactionTeamMembers/TransactionTeamMembersCSV'
import { useCurrentUserContext } from 'contexts'
import { useAgentController, useReferralPreferencesController } from 'controllers'
import { SettlementAgencyTeamMembersCSV } from 'features/settlementAgency/SettlementAgencyTeamMembersCSV'
import { useFeatureFlags } from 'hooks'
import { useAgentPartnerships, useTransactionTeams } from 'lookups'
import { Agent, User } from 'models'
import { dataOrDash, formatBoolean, formatDate, titleize, unique } from 'utils'
import styles from './AgentDetails.module.scss'
import {
  propertyTypeOptions,
  propertyTypeValues,
  propertyTypeValuesToPayload,
  worksWithOptions,
  worksWithValues,
  worksWithValuesToPayload
} from './utils/referralPreferences'

type Props = {
  agent: Agent
  stack?: boolean
}

const displayCapability = (capability?: object) => {
  if (!capability) {
    return null
  }
  return Object.keys(capability).map(key => {
    if (!capability[key]) {
      return null
    }
    if (key === 'other') {
      return <div key={key}>{titleize(capability[key])}</div>
    }
    return <div key={key}>{titleize(key)}</div>
  })
}

const useAgentUpdates = (agent: Agent) => {
  const { updateAgent } = useAgentController(agent.id)
  const { updateAgentReferralPreferences } = useReferralPreferencesController(agent.id)

  const updateAgentTransactionTeam = (transactionTeamId: string) => {
    updateAgent.mutateAsync({ transactionTeamId })
  }

  const updateAgentSettlementAgencyTeam = (settlementAgencyTeamId: string) => {
    updateAgent.mutateAsync({ settlementAgencyTeamId })
  }

  const updateAgentPreferredEscrowOfficer = (preferredEscrowOfficerUserId: string) => {
    updateAgent.mutateAsync({ preferredEscrowOfficerUserId })
  }

  const updateAgentPartnership = (payload: { partnerProgramId: string }) => {
    updateAgent.mutateAsync(payload)
  }

  const updateReferralPreferences = (payload: any) => {
    updateAgentReferralPreferences.mutateAsync(payload)
  }

  const escrowOfficersList = () => {
    let escrowOfficers: User[] = []

    if (agent.settlementAgencyTeam?.teamMemberships?.length) {
      escrowOfficers = agent.settlementAgencyTeam?.teamMemberships
        ?.filter(
          member =>
            member.role === 'escrow_officer' &&
            (agent.preferredEscrowOfficer === undefined ||
              member.user.id !== agent.preferredEscrowOfficer.id)
        )
        .map(member => member.user)
    }

    if (agent.preferredEscrowOfficer) {
      escrowOfficers.push(agent.preferredEscrowOfficer)
    }

    return escrowOfficers
  }

  return {
    updateAgentTransactionTeam,
    updateAgentSettlementAgencyTeam,
    updateAgentPreferredEscrowOfficer,
    updateAgentPartnership,
    updateReferralPreferences,
    escrowOfficersList
  }
}

export const AgentDetails = ({ agent, stack }: Props) => {
  const { transactionTeams } = useTransactionTeams()
  const {
    updateAgentTransactionTeam,
    updateAgentSettlementAgencyTeam,
    updateAgentPreferredEscrowOfficer,
    updateAgentPartnership,
    updateReferralPreferences,
    escrowOfficersList
  } = useAgentUpdates(agent)
  const { partnershipOptions } = useAgentPartnerships()
  const { salesAppPartnerships, salesAppEoEaMapping } = useFeatureFlags([
    'sales-app-partnerships',
    'sales-app-eo-ea-mapping'
  ])
  const settlementAgencyTeams = transactionTeams.filter(
    team => team.type === 'SettlementAgencyTeam'
  )

  const { isAgentAdmin, isEscrowManager } = useCurrentUserContext()

  const aboutSection = (
    <Section title="About">
      <Section>
        <Field title="Notes">
          <ViewMore height={75}>{dataOrDash(agent.notes)}</ViewMore>
        </Field>
      </Section>
      <Sections>
        <Section>
          <Field title="Service Areas">
            <ViewMore height={75}>
              {unique(agent.serviceAreas?.map(area => area.name)).join(', ')}
            </ViewMore>
          </Field>
        </Section>
        <Section>
          <Field title="Assigned Area">{agent.assignedArea?.name}</Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="ASM">{dataOrDash(agent.asm?.name)}</Field>
        </Section>
        <Section>
          <Field title="ASE">{dataOrDash(agent.ase?.name)}</Field>
        </Section>
      </Sections>
      <Sections>
        <Section>
          <Field title="Languages">
            {dataOrDash(agent.languages?.map(language => language.name).join(', '))}
          </Field>
        </Section>
        <Section>
          <Field title="MLS Identifier">{dataOrDash(agent.mlsIdentifier)}</Field>
        </Section>
      </Sections>
    </Section>
  )

  const recentTransactionsSection = (
    <Section title="Recent Transactions">
      <OnScreen>
        <MapBox markers={agent.recentTransactions} />
      </OnScreen>
    </Section>
  )

  const programsCapabilitiesSection = (
    <Section title="Capabilities">
      <FormField title="Move Safe" inline>
        {formatBoolean(agent.moveSafeCertified)}
      </FormField>
      <FormField title="Warm Transfer" inline>
        {formatBoolean(agent.referralPreferences?.acceptsWarmTransfers)}
      </FormField>
      <FormField title="Calendly" inline>
        <Block
          title={formatBoolean(agent.referralPreferences?.acceptsCalendly)}
          subtitle={
            agent.referralPreferences?.calendlyUrl ? (
              <ExternalLink href={agent.referralPreferences.calendlyUrl}>
                Link to Calendar
              </ExternalLink>
            ) : null
          }
        />
      </FormField>
      <FormField title="Trade-in+" inline>
        {formatBoolean(agent.tradeInPlusEnabled)}
      </FormField>
    </Section>
  )

  const referralPreferencesSection = (
    <Section title="Referral Preferences">
      <FormField title="Works With" inline>
        <InlineEditMultiSelect
          readOnly={!isAgentAdmin}
          values={worksWithValues(agent)}
          onBlur={values => updateReferralPreferences(worksWithValuesToPayload(values))}
          options={worksWithOptions}
        />
      </FormField>
      <FormField title="Minimum Price" inline>
        <InlineEditMoneyInput
          readOnly={!isAgentAdmin}
          onBlur={(val: string) => updateReferralPreferences({ minPrice: val })}
          value={agent.referralPreferences?.minPrice?.toString()}
        />
      </FormField>
      <FormField title="Max # of referrals per week" inline>
        {agent.weeklyCapacity?.toString()}
      </FormField>
      <FormField className={styles.wideInput} title="Accepted Property Types" inline>
        <InlineEditMultiSelect
          readOnly={!isAgentAdmin}
          values={propertyTypeValues(agent)}
          onBlur={values => updateReferralPreferences(propertyTypeValuesToPayload(values))}
          options={propertyTypeOptions}
        />
      </FormField>
      <FormField className={styles.wideInput} title="Buyer Greeting" inline>
        <InlineEditTextInput
          readOnly={!isAgentAdmin}
          value={agent.referralPreferences?.buyerIntroMessage}
          onBlur={value => updateReferralPreferences({ buyerIntroMessage: value })}
        />
      </FormField>
      <FormField className={styles.wideInput} title="Seller Greeting" inline>
        <InlineEditTextInput
          readOnly={!isAgentAdmin}
          value={agent.referralPreferences?.sellerIntroMessage}
          onBlur={value => updateReferralPreferences({ sellerIntroMessage: value })}
        />
      </FormField>
      <Field title="Specialties">
        <List>{agent.referralPreferences?.specialties}</List>
      </Field>
    </Section>
  )

  const capabilitiesSections = (
    <Sections title="Capabilities">
      <Section>
        <Field title="Virtual Meetings">
          {displayCapability(agent.referralPreferences?.virtualMeetings)}
        </Field>
        <Field title="Virtual Tours">
          {displayCapability(agent.referralPreferences?.virtualTours)}
        </Field>
        <Field title="Listing Tools">
          {displayCapability(agent.referralPreferences?.listingTools)}
        </Field>
        <Field title="Virtual Staging">
          {displayCapability(agent.referralPreferences?.virtualStaging)}
        </Field>
      </Section>
      <Section>
        <Field title="Electronic Signing">
          {displayCapability(agent.referralPreferences?.electronicSigning)}
        </Field>
        <Field title="Safety Measures">
          {displayCapability(agent.referralPreferences?.safetyMeasures)}
        </Field>
        <Field title="Accepts Private Listings">
          {agent.referralPreferences?.acceptsPrivateListings ? 'Yes' : 'No'}
        </Field>
        <Field title="Primary CRM">{agent.referralPreferences?.primaryCrm}</Field>
      </Section>
    </Sections>
  )

  const additionalInfoSection = (
    <Section title="Additional Info">
      <Field title="Transaction Team">
        <InlineEditSelect
          options={
            transactionTeams
              ? transactionTeams.map(({ name, id }) => ({
                  name,
                  value: id
                }))
              : []
          }
          value={agent.transactionTeam?.id}
          onChange={value => updateAgentTransactionTeam(value)}
          prompt="None Selected"
          addBlank
        />
        <Block
          className={styles.wrap}
          subtitle={
            agent.transactionTeam ? (
              <TransactionTeamMembersCSV members={agent.transactionTeam.teamMemberships} />
            ) : null
          }
        />
      </Field>
      {salesAppEoEaMapping.enabled && isEscrowManager && (
        <Field title="Settlement Agency Team">
          <InlineEditSelect
            options={settlementAgencyTeams?.map(({ name, id }) => ({
              name,
              value: id
            }))}
            value={agent.settlementAgencyTeam?.id}
            onChange={value => updateAgentSettlementAgencyTeam(value)}
            prompt="None Selected"
            addBlank
          />
          <Block
            className={styles.wrap}
            subtitle={
              agent.settlementAgencyTeam ? (
                <SettlementAgencyTeamMembersCSV
                  members={agent.settlementAgencyTeam.teamMemberships}
                />
              ) : null
            }
          />
        </Field>
      )}
      {salesAppEoEaMapping.enabled && !isEscrowManager && (
        <Field title="Settlement Agency Team">
          {agent.settlementAgencyTeam ? agent.settlementAgencyTeam.name : 'N/A'}
        </Field>
      )}
      {salesAppEoEaMapping.enabled && isEscrowManager && (
        <Field title="Preferred Escrow Officer">
          <InlineEditSelect
            options={escrowOfficersList().map(({ name, id }) => ({
              name,
              value: id
            }))}
            value={agent.preferredEscrowOfficer?.id}
            onChange={value => updateAgentPreferredEscrowOfficer(value)}
            prompt="None Selected"
            addBlank
          />
        </Field>
      )}
      {salesAppEoEaMapping.enabled && !isEscrowManager && (
        <Field title="Preferred Escrow Officer">
          {agent.preferredEscrowOfficer ? agent.preferredEscrowOfficer.name : 'N/A'}
        </Field>
      )}
      {salesAppPartnerships.enabled && (
        <Field title="Partnership">
          <InlineEditSelect
            options={partnershipOptions}
            value={agent.agentPartnership?.id}
            onChange={value => updateAgentPartnership({ partnerProgramId: value })}
            prompt="None Selected"
            addBlank
          />
        </Field>
      )}

      <Field title="Closings">
        <List>
          {agent.referralPreferences?.closingsName}
          {agent.referralPreferences?.closingsEmailAddress}
          {agent.referralPreferences?.closingsPhoneNumber}
        </List>
      </Field>
      <Field title="Licensing">
        <List>
          {`#: ${agent.licenseNumber}`}
          {`Type: ${agent.licenseType}`}
          {`Status: ${agent.licenseStatus}`}
          {`Issue Date: ${formatDate(agent.licenseIssueDate)}`}
        </List>
      </Field>
    </Section>
  )

  return !stack ? (
    <>
      <Sections>
        {aboutSection}
        {recentTransactionsSection}
      </Sections>
      <Sections>
        <Section>
          {programsCapabilitiesSection}
          {referralPreferencesSection}
        </Section>
        <Section>
          <Section>{capabilitiesSections}</Section>
          {additionalInfoSection}
        </Section>
      </Sections>
    </>
  ) : (
    <>
      {recentTransactionsSection}
      {aboutSection}
      {programsCapabilitiesSection}
      {referralPreferencesSection}
      {capabilitiesSections}
      {additionalInfoSection}
    </>
  )
}
