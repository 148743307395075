import { useState } from 'react'
import { DateInput, DateInputProps } from 'components'
import { useUpdateEffect } from 'hooks'
import { formatDateOrDateTime, presence } from 'utils'
import { DisplayValue, InlineProps } from '../DisplayValue'
import style from './InlineEditDateInput.module.scss'

type CustomDateInputProps = Omit<DateInputProps, 'className' | 'onClose' | 'onChange' | 'value'>
export interface InlineDateInputProps extends InlineProps, CustomDateInputProps {
  onBlur?: (val: string) => void
  onChange?: (val: string | undefined) => void
  readOnly?: boolean
  value?: string
}

export const InlineEditDateInput = ({
  value,
  isError,
  isLoading,
  includeTime,
  format,
  onChange,
  onBlur,
  readOnly,
  ...props
}: InlineDateInputProps) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(value)
  const [isEditMode, setIsEditMode] = useState(false)

  useUpdateEffect(() => {
    setCurrentValue(value)
  }, [value])

  const dateValue = currentValue || undefined
  const formattedValue = formatDateOrDateTime(dateValue, { includeTime })

  const stopEditMode = () => {
    setIsEditMode(false)
    onBlur?.(currentValue)
  }

  const handleChange = (changedValue: string) => {
    const dateValue = changedValue
    setCurrentValue(dateValue)
    onChange?.(dateValue)
  }

  if (isEditMode) {
    return (
      <DateInput
        value={presence(currentValue)}
        includeTime={false}
        {...props}
        className={style.date}
        onChange={handleChange}
        startOpen
        onClose={stopEditMode}
      />
    )
  }

  return (
    <DisplayValue
      isReadOnly={readOnly}
      format={format}
      setEditMode={setIsEditMode}
      value={formattedValue}
      isLoading={isLoading}
      isError={isError}
    />
  )
}
