import { SearchParams } from '@types'
import { removeAttributesWithNullValues } from 'utils'

const sortParam = {
  createdAt: 'created_at'
} as const

export type TPayPerLeadSettingsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'active'
>

export const getSearchParams = ({ page, perPage, active }: TPayPerLeadSettingsSearchParams) => {
  return removeAttributesWithNullValues({
    'page[number]': page ? page : undefined,
    'page[size]': perPage ? perPage : undefined,
    active: active ? active : undefined
  })
}
