// @ts-strict
import { AgentPayPerLeadSettingResponse } from 'schema'
import { Model } from '../Model'

export class AgentPayPerLeadSetting extends Model {
  active?: boolean
  id?: string | number
  location?: string
  price?: number
  qualified?: boolean
  userType?: string
  modified?: boolean

  constructor(agentPayPerLeadSettingAttributes: AgentPayPerLeadSettingResponse) {
    super()
    Object.assign(this, agentPayPerLeadSettingAttributes)
  }

  static create(
    agentPayPerLeadSettingResponse: Potential<AgentPayPerLeadSettingResponse>
  ): Potential<AgentPayPerLeadSettingResponse> {
    if (!agentPayPerLeadSettingResponse) {
      return
    }

    return new AgentPayPerLeadSetting(agentPayPerLeadSettingResponse)
  }
}
