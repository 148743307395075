// @ts-strict
import { useMutation, useQuery } from 'hooks'
import { PayPerLeadProviderSetting } from 'models'
import { fetchPayPerLeadSettings, postPayPerLeadSettings } from 'services'

const defaultSearchSettings = { perPage: 25 }

type CreatePayPerLeadSettingsPayload = {
  billingFreq: string
  buyerMonthlyCap: number
  monthlyCap: number
  settings: PayPerLeadProviderSetting[]
}

export const usePayPerLeadSettingsController = (
  providerId: string | number,
  providerType: string,
  searchParams: Record<string, string> = {}
) => {
  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const createPayPerLeadSettings = useMutation(
    (payload: CreatePayPerLeadSettingsPayload) =>
      postPayPerLeadSettings(providerId, providerType, payload),
    {
      onSuccess: () => {}
    }
  )

  const pplSettingsQuery = useQuery(
    ['ppl-settings', providerId, providerType, { searchSettings }],
    () => fetchPayPerLeadSettings(providerId, providerType, searchSettings),
    { refetchOnWindowFocus: false }
  )

  return {
    createPayPerLeadSettings,
    pplSettingsQuery
  }
}
