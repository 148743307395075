import { BBYSExtension, BBYSLead } from 'models'
import { BBYSExtensionAttributes } from 'schema'
import { stringifyValue } from 'utils'
import { CCBBYSLeadDetailsProps } from '../..'

export const getBBYSExtensionInputProps = (
  bbysLead?: BBYSLead,
  updateBbysLoan?: CCBBYSLeadDetailsProps['updateBBYSLoan' | 'createBBYSLoan'],
  extension?: BBYSExtension
) => {
  const isSectionEmpty = bbysLead?.bbysExtensions?.length === 0 && !extension
  const handleUpdateExtensionLoan = ({
    key,
    val
  }: {
    key: keyof BBYSExtensionAttributes
    val: string
  }) => {
    if (!extension) {
      // POST
      updateBbysLoan.mutate({
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id
        }
      })
    } else {
      // PUT
      updateBbysLoan.mutate({
        id: extension?.id,
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id
        }
      })
    }
  }

  const getExtensionProps = (key: keyof BBYSExtensionAttributes) => {
    const value = stringifyValue(extension?.[key]) || ''
    return {
      value,
      onBlur: (val: string) => handleUpdateExtensionLoan({ key, val })
    }
  }

  return {
    getExtensionProps,
    isSectionEmpty
  }
}
