import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { Document, RawDocument } from '../utils'

type Error = Record<number, string>
type UseDocumentsListArgs = { initialDocuments?: Document[] }

function formatRawDocuments(rawDocs: RawDocument[]): Document[] {
  return rawDocs.map((rd, i) => ({ id: i + 1, ...rd }))
}

export const useDocumentsList = ({ initialDocuments = [] }: UseDocumentsListArgs = {}) => {
  const [documents, setDocuments] = useState<Document[]>(formatRawDocuments(initialDocuments))
  const [errors, setErrors] = useState<Error>({} as Error)

  const create = () => {
    if (documents.length > 0) {
      const lastDoc = documents[documents.length - 1]
      const isLastDocValid = validateField(lastDoc.id)

      if (!isLastDocValid) {
        return
      }
    }

    setDocuments(prev => [
      ...prev,
      {
        id: prev.length + 1,
        title: '',
        enabled: true,
        notes: '',
        default: false,
        uploaded: false
      }
    ])
  }

  const update = (id: number, document: Partial<Document>) => {
    setDocuments(prev =>
      prev.map(doc => {
        if (doc.id === id) {
          return { ...doc, ...document }
        }
        return doc
      })
    )
    clearError(id)
  }

  const remove = (id: number) => {
    setDocuments(prev => prev.filter(field => field.id !== id))
    clearError(id)
  }

  const createError = (id: number, error = 'Enter a document name') => {
    setErrors(prev => ({ ...prev, [id]: error }))
  }

  const clearError = (id: number) => {
    setErrors(prev => {
      if (!prev?.[id]) {
        return prev
      }

      delete prev?.[id]
      return prev
    })
  }

  const validateField = (id: number) => {
    const field = documents.find(doc => doc.id === id)

    if (!field.title) {
      createError(id)
      return false
    }
    return true
  }

  return {
    documents,
    errors,
    create,
    update,
    remove,
    validateField,
    createError,
    clearError
  }
}

type DocumentsListReturn = ReturnType<typeof useDocumentsList>

export const DocumentsListContext = createContext<DocumentsListReturn | undefined>(undefined)

export const DocumentsListProvider = ({
  children,
  ...props
}: PropsWithChildren<DocumentsListReturn>) => {
  return <DocumentsListContext.Provider value={props}>{children}</DocumentsListContext.Provider>
}

export const useDocumentsListContext = () => {
  const ctx = useContext(DocumentsListContext)
  if (!ctx) {
    throw new Error('Missing DocumentsListProvider')
  }
  return ctx
}
