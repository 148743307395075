// @ts-strict
import { TableHeaderRow, TableHeaderColumn as TH } from 'components'
import { useListContext } from 'contexts'

export const FilesTableHeader = () => {
  const { showColumn } = useListContext()
  return (
    <TableHeaderRow>
      {showColumn('name') && (
        <TH
          sort={[{ name: 'Name', slug: 'name' }]}
          colSpan={showColumn('incomingResidence') ? 4 : 8}
        />
      )}
      {showColumn('incomingResidence') && <TH colSpan={4} name="IR Address" />}
      {showColumn('sharedExternally') && <TH colSpan={3} name="Access" />}
      {showColumn('createdAt') && (
        <TH sort={[{ name: 'Created At', slug: 'createdAt' }]} colSpan={2} />
      )}
      {showColumn('actions') && <TH sort={[{ name: 'Actions', slug: 'actions' }]} colSpan={1} />}
    </TableHeaderRow>
  )
}
