import { BBYSLead, BBYSLoan } from 'models'
import { BBYSLeadAttributes, BBYSLoanAttributes } from 'schema'
import { stringifyValue } from 'utils'
import { CCBBYSLeadDetailsProps } from '../..'

export const getBBYSLoanInputProps = (
  bbysLead?: BBYSLead,
  updateBbysLoan?: CCBBYSLeadDetailsProps['updateBBYSLoan'],
  repaper?: BBYSLoan
) => {
  const hasOriginalLoan = !!bbysLead?.originalBBYSLoan?.id

  const isSectionEmpty = !bbysLead?.originalBBYSLoan?.id && !repaper

  const handleUpdateOriginalLoan = ({
    key,
    val
  }: {
    key: keyof BBYSLeadAttributes | keyof BBYSLoanAttributes
    val: string
  }) => {
    // POST
    if (!hasOriginalLoan) {
      updateBbysLoan.mutate({
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id,
          loanType: 'original'
        }
      })
    } else {
      // PUT
      updateBbysLoan.mutate({
        id: bbysLead?.originalBBYSLoan?.id,
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id
        }
      })
    }
  }

  const handleUpdateRepaperLoan = ({
    key,
    val
  }: {
    key: keyof BBYSLeadAttributes | keyof BBYSLoanAttributes
    val: string
  }) => {
    if (!repaper) {
      // POST
      updateBbysLoan.mutate({
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id,
          loanType: 'repaper'
        }
      })
    } else {
      // PUT
      updateBbysLoan.mutate({
        id: repaper?.id,
        payload: {
          [key]: val,
          bbys_lead_id: bbysLead.id,
          loanType: 'repaper'
        }
      })
    }
  }

  const getOriginalLoanProps = (key: keyof BBYSLeadAttributes | keyof BBYSLoanAttributes) => {
    const value = stringifyValue(bbysLead?.originalBBYSLoan?.[key]) || ''
    return {
      value,
      onBlur: (val: string) => {
        if (val === value) {
          return
        }
        handleUpdateOriginalLoan({ key, val })
      }
    }
  }

  const getRepaperLoanProps = (key: keyof BBYSLeadAttributes | keyof BBYSLoanAttributes) => {
    const value = stringifyValue(repaper?.[key]) || ''
    return {
      value,
      onBlur: (val: string) => {
        if (val === value) {
          return
        }
        handleUpdateRepaperLoan({ key, val })
      }
    }
  }

  return {
    getOriginalLoanProps,
    getRepaperLoanProps,
    isSectionEmpty
  }
}
