// @ts-strict
import { useMutation, useQueryClient } from 'react-query'
import { FILES_CACHE_KEY, useQuery } from 'hooks'
import {
  fetchFileUploads,
  type FetchPresignedLinkProps,
  TFileUploadsSearchParams,
  TPostFileUploadPayload,
  uploadFile
} from 'services'
import { getPageCount } from 'utils'

const defaultSearchSettings: Pick<TFileUploadsSearchParams, 'sortDir' | 'perPage' | 'sortBy'> = {
  sortBy: 'createdAt',
  sortDir: 'desc',
  perPage: 10
}

export const useFileUploadsController = (
  searchParams: TFileUploadsSearchParams = { leadId: '' },
  options?: {
    fileMetadata?: Omit<FetchPresignedLinkProps, 'mime'>
    onSuccess?: (data: any) => void
  }
) => {
  const queryCache = useQueryClient()
  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(FILES_CACHE_KEY)
    }
  }

  const searchSettings = {
    ...defaultSearchSettings,
    ...searchParams
  }

  const { data } = useQuery([FILES_CACHE_KEY, searchSettings], () =>
    fetchFileUploads(searchSettings)
  )

  const upload = useMutation(
    (payload: TPostFileUploadPayload) =>
      uploadFile(searchParams.leadId, payload, {
        ...options,
        fileMetadata: options?.fileMetadata
      }),
    defaultCallback
  )

  return {
    uploadFile: upload,
    fileUploads: data?.fileUploads,
    filePagesCount: getPageCount(data?.count, defaultSearchSettings.perPage)
  }
}
