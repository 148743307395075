import { AgentPayPerLeadSetting, InvestorPayPerLeadSetting } from 'models'
import { SearchParamsSettings } from 'services/AgentLeads/getSearchParams'
import { getPplSettingsUrl } from 'services/getUrl'
import { getRequest } from 'utils'
import { deserializeAsync } from 'utils'
import { getSearchParams } from './getSearchParams'

export const fetchPayPerLeadSettings = async (
  providerId: string | number,
  providerType: string,
  settings: SearchParamsSettings
): Promise<{
  pplProviderSettings: AgentPayPerLeadSetting[] | InvestorPayPerLeadSetting[]
  totalCount: number
}> => {
  const url = getPplSettingsUrl({
    pathParams: {
      provider_type: providerType,
      provider_id: providerId
    },
    action: '',
    searchParams: {
      ...getSearchParams(settings)
    }
  })

  const response = await getRequest(url)
  const klass = providerType === 'Agent' ? AgentPayPerLeadSetting : InvestorPayPerLeadSetting
  const pplProviderSettings = await deserializeAsync(response.data, { transform: klass.create })

  return {
    pplProviderSettings,
    totalCount: response.data.meta.total as number
  }
}
