// @ts-strict
import { useEffect } from 'react'
import { Block, Button, LoadingText, Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { AgentAgreementsProvider, useAgentAgreementsContext, useAgentContext } from 'contexts'
import { useAgentController, useDocumentTitle } from 'hooks'
import { AgentAgreement, PplProviderAgreement } from 'models'
import { AgentSchema } from 'schema'
import { agentPplProviderAgreementShape } from 'services/Agent/agentShapes'
import { capitalize, formatDate, formatTime } from 'utils'

export const AgentAgreementTab = () => {
  const { agent } = useAgentContext()

  const { setTitle } = useDocumentTitle()

  useEffect(() => setTitle(`${agent?.fullName} - Agent Agreements`), [agent, setTitle])

  return (
    <AgentAgreementsProvider agentId={agent?.id}>
      <AgentAgreementsTabContents />
    </AgentAgreementsProvider>
  )
}

const agentResponseShape: AgentSchema = {
  agent: agentPplProviderAgreementShape
}

const AgentAgreementsTabContents = () => {
  const { agentAgreementsQuery } = useAgentAgreementsContext()
  const { agent: baseAgent } = useAgentContext()
  const { agent } = useAgentController(baseAgent.id, true, agentResponseShape)

  if (agentAgreementsQuery.isLoading || !agent) {
    return <LoadingText />
  }

  const agentAgreements = agentAgreementsQuery.data
  const pplProviderAgreements = agent.pplProviderAgreements || []

  const allAgreements = [...agentAgreements, ...pplProviderAgreements]

  return (
    <Table data-testid="agent-agreements-table">
      <TR>
        <TH>Type</TH>
        <TH>Agreement Name</TH>
        <TH>Issued At</TH>
        <TH>Voided At</TH>
        <TH>Signed At</TH>
        <TH>Version</TH>
        <TH>Commission</TH>
        <TH>Status</TH>
        <TH>Action</TH>
        <TH>Note</TH>
      </TR>
      <TableResults type="AgentAgreements" colSpan={6}>
        {allAgreements.map((providerAgreement: AgentAgreement | PplProviderAgreement) => (
          <AgentAgreementRow key={providerAgreement.id} providerAgreement={providerAgreement} />
        ))}
      </TableResults>
    </Table>
  )
}

const AgentAgreementRow = ({
  providerAgreement
}: {
  providerAgreement: AgentAgreement | PplProviderAgreement
}) => {
  const { agentAgreementDownload } = useAgentAgreementsContext()
  const agreementType = providerAgreement instanceof AgentAgreement ? 'Agreement' : 'Ammendment'
  const s3Path = providerAgreement.s3Path

  return (
    <Tr data-testid={`agent-agreement-id-${providerAgreement.id}`}>
      <Td>{agreementType}</Td>
      <Td>{providerAgreement.agreement?.name}</Td>
      <Td>
        <Block
          title={formatDate(providerAgreement.createdAt)}
          subtitle={formatTime(providerAgreement.createdAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(providerAgreement.voidedAt)}
          subtitle={formatTime(providerAgreement.voidedAt)}
        />
      </Td>
      <Td>
        <Block
          title={formatDate(providerAgreement.signedAt)}
          subtitle={formatTime(providerAgreement.signedAt)}
        />
      </Td>
      <Td>{providerAgreement.agreement?.version}</Td>
      <Td>{providerAgreement.agreement?.commissionSplit}%</Td>
      <Td>{capitalize(providerAgreement.status!)}</Td>
      <Td>
        {s3Path && (
          <Button onClick={() => agentAgreementDownload.mutate(s3Path)} as="link">
            View
          </Button>
        )}
      </Td>
      <Td>{providerAgreement.note}</Td>
    </Tr>
  )
}
