import { AgentAttributes, AgentAttributesAndAssociations } from 'schema'

const listFields: Array<keyof AgentAttributes> = [
  'eliteStatus',
  'firstName',
  'lastActivityAt',
  'lastName',
  'lastSignedInAt',
  'lastTouchedAt',
  'officeDisplayName',
  'pictureThumbUrl',
  'referralStats',
  'referralPreferences',
  'tier',
  'servicesOpportunitiesCounts',
  'weeklyCapacity'
]

export const agentListShape: AgentAttributesAndAssociations = [
  ...listFields,
  { agentTeamMemberships: ['role'] },
  {
    marketplaceProgramAgents: [
      'pending',
      'enrolled',
      'declined',
      { marketplaceProgram: ['name', 'slug'] }
    ]
  },
  {
    'preferredEscrowOfficer:user': ['id', 'name']
  },
  { partnerProgram: ['id', 'name', 'description'] },
  { investor: ['name'] },
  { state: ['code', 'name'] },
  {
    servicesOpportunities: ['productType', { 'status:servicesOpportunityStatus': ['name', 'slug'] }]
  },
  {
    referralPreferences: [
      'active',
      'isFallbackAgent',
      'leadRoutingWarmTransferOptIn',
      'prefersBuyer',
      'prefersSeller'
    ]
  }
]

export const agentMetricsShape: AgentAttributesAndAssociations = [
  'homesEnrollmentDate',
  'referralsEnrollmentDate',
  'homesActivationDate',
  'lastHomesLeadDate',
  'lastReferralLeadDate',
  {
    platformMetrics: [
      'lastUpdatedAt',
      'agentToHlTradeInCloses',
      'escrowOpens',
      'agentToHlCashOfferLeads',
      'metInPersonReferrals',
      'agentToHlTradeInLeads',
      'closedReferrals',
      'connectedReferrals',
      'agentToHomelightSimpleSaleCloses',
      'agentLeftVmReferrals',
      'agentToHlCashOfferApprovalsCompleted',
      'meetingScheduledReferrals',
      'agentToHomelightSimpleSaleApprovalsCompleted',
      'failedReferrals',
      'agentToHlTradeInApprovalsCompleted',
      'listingReferrals',
      'agentToHomelightSimpleSaleLeads',
      'agentToHlCashOfferCloses',
      'homesCloses',
      'homesLeads',
      'homesApprovals',
      'introducedReferrals',
      'escrowCloses'
    ]
  },
  { agentTeamMemberships: ['role', { agentTeam: ['name'] }] }
]

export const agentItemShape: AgentAttributesAndAssociations = [
  'firstName',
  'lastName',
  'officeDisplayName',
  'pictureThumbUrl',
  'tier',
  { state: ['code', 'name'] }
]
