import { BBYSLoan } from 'models/BBYSLoan/BBYSLoan'

export const getSortedLoans = (
  bbysLoans: BBYSLoan[]
): { additionalLoans?: BBYSLoan[]; primaryLoan?: BBYSLoan | undefined } => {
  if (!bbysLoans || bbysLoans.length === 0) {
    return {
      primaryLoan: undefined,
      additionalLoans: []
    }
  }

  const loans = bbysLoans.sort((a, b) => {
    const dateA = a?.createdAt ? new Date(a?.createdAt).getTime() : Infinity
    const dateB = b?.createdAt ? new Date(b?.createdAt).getTime() : Infinity
    return dateA - dateB
  })

  const [primaryLoan, ...additionalLoans] = loans
  return {
    primaryLoan,
    additionalLoans
  }
}
