import { useEffect } from 'react'
import { Box, Spinner } from '@foundation/components'
import { Contents } from 'components/Contents'
import { AgentReferrals } from 'content/Agent/AgentBilling'
import { InvestorBilling, PaymentInformation } from 'content/Investor/InvestorBilling'
import { BillingCycles, PplBillingInfo } from 'content/Provider'
import {
  AgentPayPerLeadReferralsProvider,
  InvestorPayPerLeadSettingsProvider,
  useInvestorContext
} from 'contexts'
import {
  useAgentPayPerLeadReferralsController,
  useInvestorPayPerLeadSettingsController,
  usePayPerLeadBillingInfoController
} from 'controllers'
import { useDocumentTitle } from 'hooks'
import { AgentPayPerLeadReferral, Investor, PayPerLeadBillingInfo } from 'models'
import styles from './InvestorBillingTab.module.scss'

export const InvestorBillingTab = () => {
  const { investor } = useInvestorContext()
  const { setTitle } = useDocumentTitle()

  useEffect(() => setTitle(`${investor.name} - Investor`), [investor, setTitle])

  const { currentPayPerLeadReferralsQuery, pastPayPerLeadReferralsQuery } =
    useAgentPayPerLeadReferralsController(investor.id, null, 'Investor')
  const { payPerLeadBillingInfosQuery } = usePayPerLeadBillingInfoController(
    investor.id,
    'Investor'
  )
  const { investorPayPerLeadSettingsQuery } = useInvestorPayPerLeadSettingsController(investor.id)

  const { data: providerPplData }: { data: Investor } = investorPayPerLeadSettingsQuery
  const { data: billingCycles }: { data: PayPerLeadBillingInfo[] } = payPerLeadBillingInfosQuery
  const { data: currentReferrals }: { data: AgentPayPerLeadReferral[] } =
    currentPayPerLeadReferralsQuery
  const { data: pastReferrals }: { data: AgentPayPerLeadReferral[] } = pastPayPerLeadReferralsQuery

  if (investorPayPerLeadSettingsQuery.isLoading) {
    return (
      <Box margin="$3">
        <Spinner size="xs" label="Loading Pay-Per-Lead Information..." />
      </Box>
    )
  }

  return (
    <Contents className={styles.ppl_container}>
      <InvestorPayPerLeadSettingsProvider investorId={investor?.id}>
        <Contents className={styles.billing_details}>
          <InvestorBilling investor={providerPplData} />
          <PaymentInformation investor={providerPplData} />
          <PplBillingInfo provider={providerPplData} />
        </Contents>
      </InvestorPayPerLeadSettingsProvider>
      <AgentPayPerLeadReferralsProvider agentId={investor?.id}>
        <Contents>
          <BillingCycles provider={investor} billingCycles={billingCycles} />
          <AgentReferrals
            agent={investor}
            currentReferrals={currentReferrals}
            pastReferrals={pastReferrals}
            providerType="Investor"
          />
        </Contents>
      </AgentPayPerLeadReferralsProvider>
    </Contents>
  )
}
