import { useEffect, useState } from 'react'
import { Spinner } from '@foundation/components'
import {
  FormField,
  InlineEditDateInput,
  InlineEditDollarsAndCentsInput,
  InlineEditPercentageInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { useLeadContext } from 'contexts'
import { bbysLeadExtensionTermsOptions, bbysLeadWarehouseOptions } from 'lookups'
import { BBYSExtension, BBYSLoan } from 'models'
import { cx, dataOrDash, dateDiffInDays, formatDate } from 'utils'
import { getSortedLoans } from 'utils'
import tableStyles from '../../../../@foundation/components/Table/Table.module.scss'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import repaperStyles from './ClientRepaperSection.module.scss'
import { getBBYSExtensionInputProps, getBBYSLoanInputProps } from './utils'

type RepaperLoan = BBYSLoan & {
  loanType: 'repaper'
}

type Props = Pick<CCBBYSLeadDetailsProps, 'lead' | 'updateBBYSLoan' | 'createBBYSLoan'> & {
  repaper?: RepaperLoan
}

type SectionProps = Props & {
  addedCount: string
  buttonText?: string
  isLeadUpdating?: boolean
  isLoading?: boolean
  isMaxed?: boolean
  onClick?: () => void
}

const RepaperSection = ({
  addedCount,
  lead,
  repaper,
  updateBBYSLoan,
  createBBYSLoan,
  onClick,
  buttonText,
  isMaxed,
  isLoading,
  isLeadUpdating
}: SectionProps) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getRepaperLoanProps, isSectionEmpty } = getBBYSLoanInputProps(
    bbysLead,
    !repaper ? createBBYSLoan : updateBBYSLoan,
    repaper
  )

  const isUpdatingBBYSLoan = updateBBYSLoan.isLoading || createBBYSLoan.isLoading
  const isPrimaryDisabled = isSectionEmpty && buttonText === 'Add Repaper'
  const buttonCx =
    buttonText === 'Add Repaper'
      ? repaperStyles[
          `repaper-section-add-button${
            isPrimaryDisabled || isMaxed || isLeadUpdating || isUpdatingBBYSLoan ? '-disabled' : ''
          }`
        ]
      : repaperStyles[
          `repaper-section-remove-button${isLeadUpdating || isUpdatingBBYSLoan ? '-disabled' : ''}`
        ]

  return (
    <Sections
      title={`Buy Before You Sell Repaper ${addedCount}`}
      actions={
        isLoading ? (
          <Spinner size="lg" data-testid="loadingActivities" />
        ) : (
          <button
            disabled={isPrimaryDisabled || isMaxed || isUpdatingBBYSLoan}
            className={buttonCx}
            onClick={onClick}
          >
            {buttonText}
          </button>
        )
      }
      className={repaperStyles['repaper-section-header']}
    >
      <Section>
        <FormField title="Start Date" inline>
          <InlineEditDateInput {...getRepaperLoanProps('repaperStartedAt')} />
        </FormField>
        <FormField title="Funded Date" inline>
          <InlineEditDateInput {...getRepaperLoanProps('repaperFundedAt')} />
        </FormField>
        <FormField title="Loan Number" inline>
          <InlineEditTextInput maxLength={255} {...getRepaperLoanProps('loanNumber')} />
        </FormField>
        <FormField title="Loan Amount" inline>
          <InlineEditDollarsAndCentsInput {...getRepaperLoanProps('loanFundingAmount')} />
        </FormField>
      </Section>

      <Section>
        <FormField title="Warehouse Facility" inline>
          <InlineEditSelect
            {...getRepaperLoanProps('warehouseFacility')}
            options={bbysLeadWarehouseOptions}
          />
        </FormField>
        <FormField title="Note Date" inline>
          <InlineEditDateInput {...getRepaperLoanProps('repaperNoteAt')} />
        </FormField>
        <FormField title="Recording Fee" inline>
          <InlineEditDollarsAndCentsInput {...getRepaperLoanProps('recordingFee')} />
        </FormField>
        <FormField title="Notary Fee" inline>
          <InlineEditDollarsAndCentsInput {...getRepaperLoanProps('notaryFee')} />
        </FormField>
        <FormField title="Documentary and Intangible Taxes" inline>
          <InlineEditDollarsAndCentsInput
            {...getRepaperLoanProps('documentaryAndIntangibleTaxes')}
          />
        </FormField>
      </Section>
    </Sections>
  )
}

type ExtensionSectionProps = Pick<CCBBYSLeadDetailsProps, 'lead'> & {
  addedCount: string
  buttonText?: string
  extension?: BBYSExtension
  isDisabled?: boolean
  isMaxed?: boolean
  isRemoving?: boolean
  onClick?: () => void
}

const ExtensionSection = ({
  addedCount,
  lead,
  extension,
  onClick,
  buttonText,
  isMaxed,
  isDisabled,
  isRemoving
}: ExtensionSectionProps) => {
  const { createBBYSExtension, updateBBYSExtension } = useLeadContext()
  const [termFeePercentage, setTermFeePercentage] = useState<string>('')

  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getExtensionProps, isSectionEmpty } = getBBYSExtensionInputProps(
    bbysLead,
    !extension ? createBBYSExtension : updateBBYSExtension,
    extension
  )
  const isBBYSExtensionUpdating = updateBBYSExtension.isLoading || createBBYSExtension.isLoading
  const isPrimaryDisabled = isSectionEmpty && buttonText === 'Add Extension'

  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const dateUntilExtensionExpiration = dateDiffInDays(extension?.expiresAt, today)

  const buttonCx =
    buttonText === 'Add Extension'
      ? repaperStyles[
          `extension-section-add-button${
            isPrimaryDisabled || isMaxed || isDisabled || isBBYSExtensionUpdating ? '-disabled' : ''
          }`
        ]
      : repaperStyles[
          `extension-section-remove-button${
            isDisabled || isBBYSExtensionUpdating ? '-disabled' : ''
          }`
        ]

  const { value: feePercentageInput, onBlur } = getExtensionProps('feePercentage')

  useEffect(() => {
    if (extension?.term === '60_days') {
      setTermFeePercentage('0.012')
      onBlur('0.012')
    } else if (extension?.term === '120_days') {
      setTermFeePercentage('0.024')
      onBlur('0.024')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extension?.term])

  return (
    <Sections
      title={`Buy Before You Sell Extension ${addedCount}`}
      actions={
        isRemoving ? (
          <Spinner size="lg" data-testid="loadingActivities" />
        ) : (
          <button
            disabled={isPrimaryDisabled || isMaxed || isBBYSExtensionUpdating}
            className={buttonCx}
            onClick={onClick}
          >
            {buttonText}
          </button>
        )
      }
      className={repaperStyles['extension-section-header']}
    >
      <Section>
        <FormField title="Extension expiration" inline>
          <InlineEditDateInput {...getExtensionProps('expiresAt')} />
        </FormField>
        <FormField title="Extension terms" inline>
          <InlineEditSelect
            {...getExtensionProps('term')}
            options={bbysLeadExtensionTermsOptions}
          />
        </FormField>
        <FormField title="Extension sent" inline>
          <InlineEditDateInput {...getExtensionProps('sentAt')} />
        </FormField>
        <FormField title="Extension signed" inline>
          <InlineEditDateInput {...getExtensionProps('signedAt')} />
        </FormField>
      </Section>

      <Section>
        <FormField
          title="Days Until Extension expiration"
          inline
          valueWrapperClassName={cx(
            tableStyles.daysSinceField,
            dateUntilExtensionExpiration <= 10 ? tableStyles.red : '',
            dateUntilExtensionExpiration >= 11 && dateUntilExtensionExpiration <= 30
              ? tableStyles.yellow
              : ''
          )}
        >
          {dataOrDash(dateUntilExtensionExpiration)}
        </FormField>
        <FormField title="Extension fee %" inline>
          <InlineEditPercentageInput
            {...getExtensionProps('feePercentage')}
            value={termFeePercentage || feePercentageInput}
          />
        </FormField>
        <FormField title="Extension fee charged" inline>
          <InlineEditDollarsAndCentsInput {...getExtensionProps('feeCharged')} />
        </FormField>
        <FormField title="Extension Risk Adjusted Value" inline>
          <InlineEditDollarsAndCentsInput {...getExtensionProps('extensionRiskAdjustedValue')} />
        </FormField>
      </Section>
    </Sections>
  )
}

type ClientRepaperSectionProps = Pick<
  CCBBYSLeadDetailsProps,
  'lead' | 'updateBBYSLoan' | 'createBBYSLoan' | 'removeBBYSLoan'
>

export const ClientRepaperSection = ({
  lead,
  updateBBYSLoan,
  createBBYSLoan,
  removeBBYSLoan
}: ClientRepaperSectionProps) => {
  const bbysLead = lead?.bbysProviderLead?.bbysLead
  const { removeBBYSExtension } = useLeadContext()
  const { primaryLoan: primaryRepaper, additionalLoans: additionalRepapers } = getSortedLoans(
    bbysLead?.bbysLoans.filter(({ loanType }) => loanType === 'repaper')
  )

  const { primaryLoan: primaryExtension, additionalLoans: additionalExtensions } = getSortedLoans(
    bbysLead?.bbysExtensions
  )

  const [additionalRepaperCount, setAdditionalRepaperCount] = useState(additionalRepapers.length)
  const [additionalExtensionCount, setAdditionalExtensionCount] = useState(
    additionalExtensions.length
  )
  const [removingRepaperId, setRemovingRepaperId] = useState<string | null>(null)
  const [removingExtensionId, setRemovingExtensionId] = useState<string | null>(null)

  const [isLeadUpdating, setIsLeadUpdating] = useState(false)

  // Watch for lead changes
  useEffect(() => {
    if (isLeadUpdating && bbysLead?.bbysLoans) {
      // Check if the removing repaper is no longer in the loans
      if (removingRepaperId) {
        const repaperStillExists = bbysLead?.bbysLoans.some(loan => loan.id === removingRepaperId)

        if (!repaperStillExists) {
          setIsLeadUpdating(false)
          setRemovingRepaperId(null)
          setAdditionalRepaperCount(prev => prev - 1)
        }
      } else if (removingExtensionId) {
        const extensionStillExists = bbysLead?.bbysExtensions.some(
          extension => extension.id === removingExtensionId
        )

        if (!extensionStillExists) {
          setIsLeadUpdating(false)
          setRemovingExtensionId(null)
          setAdditionalExtensionCount(prev => prev - 1)
        }
      }
    }
  }, [isLeadUpdating, bbysLead, removingRepaperId, removingExtensionId])

  const handleAddExtension = () => {
    if (additionalExtensions.length < 2) {
      setAdditionalExtensionCount(prev => prev + 1)
    }
  }

  const handleRemoveExtension = (index: number) => {
    if (additionalExtensionCount <= 2) {
      if (additionalExtensions[index]?.id) {
        setRemovingExtensionId(additionalExtensions[index]?.id)
        removeBBYSExtension.mutate(
          {
            id: additionalExtensions[index]?.id,
            bbysLeadId: bbysLead?.id
          },
          {
            onSuccess: () => {
              setIsLeadUpdating(true)
            }
          }
        )
      } else {
        setAdditionalExtensionCount(prev => prev - 1)
      }
    }
  }

  const handleAddRepaper = () => {
    if (additionalRepaperCount < 2) {
      setAdditionalRepaperCount(prev => prev + 1)
    }
  }

  const handleRemoveRepaper = (index: number) => {
    if (additionalRepaperCount <= 2) {
      if (additionalRepapers[index]?.id) {
        setRemovingRepaperId(additionalRepapers[index]?.id)
        removeBBYSLoan.mutate(
          {
            id: additionalRepapers[index]?.id,
            bbysLeadId: bbysLead?.id
          },
          {
            onSuccess: () => {
              setIsLeadUpdating(true)
            }
          }
        )
      } else {
        setAdditionalRepaperCount(prev => prev - 1)
      }
    }
  }

  return (
    <>
      <Sections
        title="Buy Before You Sell Repaper"
        className={repaperStyles['default-repaper-section-header']}
      >
        <RepaperSection
          lead={lead}
          repaper={primaryRepaper as RepaperLoan}
          updateBBYSLoan={updateBBYSLoan}
          createBBYSLoan={createBBYSLoan}
          buttonText="Add Repaper"
          onClick={() => handleAddRepaper()}
          isMaxed={additionalRepaperCount >= 2}
          isLeadUpdating={!!removingRepaperId}
          addedCount={primaryRepaper ? '(1)' : ''}
        />
      </Sections>
      {[...Array(additionalRepaperCount)].map((_, index) => (
        <RepaperSection
          key={additionalRepapers[index]?.id || index}
          lead={lead}
          repaper={additionalRepapers[index] as RepaperLoan}
          updateBBYSLoan={updateBBYSLoan}
          createBBYSLoan={createBBYSLoan}
          buttonText="Remove"
          isLoading={removingRepaperId === additionalRepapers[index]?.id}
          onClick={() => {
            handleRemoveRepaper(index)
          }}
          addedCount={additionalRepapers[index]?.id ? `(${index + 2})` : ''}
        />
      ))}
      <ExtensionSection
        lead={lead}
        extension={primaryExtension as BBYSExtension}
        buttonText="Add Extension"
        onClick={() => handleAddExtension()}
        isMaxed={additionalExtensionCount >= 2}
        isDisabled={!!removingExtensionId}
        addedCount={primaryExtension ? '(1)' : ''}
      />
      {[...Array(additionalExtensionCount)].map((_, extensionIndex) => (
        <ExtensionSection
          key={additionalExtensions[extensionIndex]?.id || extensionIndex}
          lead={lead}
          extension={additionalExtensions[extensionIndex] as BBYSExtension}
          buttonText="Remove"
          isRemoving={removingExtensionId === additionalExtensions[extensionIndex]?.id}
          isDisabled={!!removingExtensionId}
          onClick={() => {
            handleRemoveExtension(extensionIndex)
          }}
          addedCount={additionalExtensionCount[extensionIndex]?.id ? `(${extensionIndex + 2})` : ''}
        />
      ))}
    </>
  )
}
