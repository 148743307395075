// @ts-strict
import { useState } from 'react'
import { AxiosError } from 'axios'
import { Block, DownloadButton, TableCell as TD, Tooltip, TableRow as TR } from 'components'
import { useListContext } from 'contexts'
import { FileUpload } from 'models'
import { deleteRequest, formatDate } from 'utils'
import {
  Button,
  Dialog,
  Flex,
  Icon,
  IconButton,
  useToastContext
} from '../../../@foundation/components'
import { FILES_CACHE_KEY, useMutation, useQueryClient } from '../../../hooks'
import { getFilesUrl } from '../../../services/getUrl'
import styles from './FilesTable.module.scss'

type TProps = { file: FileUpload; showDeleteAction?: boolean }

export const FilesTableRow = ({ file }: TProps) => {
  const { showColumn } = useListContext()
  const queryCache = useQueryClient()
  const address = file.fileAttachment?.subAttachable?.incomingMasterProperty?.displayAddress
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const toast = useToastContext()

  const { mutate, isLoading } = useMutation(() => deleteRequest(getFilesUrl({ id: file.id })), {
    onSuccess: async () => {
      await queryCache.invalidateQueries(FILES_CACHE_KEY)
      setIsDeleteDialogOpen(false)
    },
    onError: (error: AxiosError) => {
      console.error(error.response)
      toast({
        title: `Oops! Something went wrong. ${error?.response?.data?.errors}`,
        status: 'error'
      })
    }
  })

  return (
    <TR className={styles.tr}>
      {showColumn('name') && (
        <TD colSpan={showColumn('incomingResidence') ? 4 : 8}>
          <Block
            title={
              file.sharedExternally ? (
                file.name
              ) : (
                <DownloadButton url={file?.fileAttachment?.downloadUrl}>
                  <Tooltip>{file.name}</Tooltip>
                </DownloadButton>
              )
            }
          />
        </TD>
      )}
      {showColumn('incomingResidence') && (
        <TD colSpan={4}>
          <Block title={<Tooltip>{address}</Tooltip>} />
        </TD>
      )}
      {showColumn('sharedExternally') && (
        <TD colSpan={3}>
          <Block title={<Tooltip>{file.sharedExternally ? 'External' : 'Internal'}</Tooltip>} />
        </TD>
      )}
      {showColumn('createdAt') && (
        <TD colSpan={2}>
          <Block title={<Tooltip>{formatDate(file.createdAt)}</Tooltip>} />
        </TD>
      )}
      {showColumn('actions') && (
        <TD colSpan={1}>
          <Block
            title={
              <Tooltip text="Delete Document">
                {file.sharedExternally ? (
                  <Dialog.Root isOpen={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                    <Dialog.Trigger>
                      <IconButton
                        icon="Close"
                        title="Delete Document"
                        variant="outline"
                        size="xs"
                        onClick={() => mutate()}
                        isLoading={isLoading}
                      />
                    </Dialog.Trigger>
                    <Dialog.Content>
                      <Dialog.Header>Delete Document</Dialog.Header>
                      <Dialog.Body>
                        <div className={styles.dialogBodyWrapper}>
                          Delete “{file.name}” document from the transaction?
                          <p className={styles.warning}>
                            <Icon path="InfoOutlined" size="lg" />
                            Deleting this document will remove it from the Lender Portal and prevent
                            external users from being able to access it.
                          </p>
                        </div>
                      </Dialog.Body>
                      <Dialog.Footer>
                        <Flex justifyContent="end" gap="$4">
                          <Button
                            color="neutralLight"
                            onClick={() => setIsDeleteDialogOpen(false)}
                            isDisabled={isLoading}
                          >
                            Cancel
                          </Button>
                          <Button color="primary" onClick={() => mutate()} isLoading={isLoading}>
                            Submit
                          </Button>
                        </Flex>
                      </Dialog.Footer>
                    </Dialog.Content>
                  </Dialog.Root>
                ) : (
                  <IconButton
                    icon="Close"
                    title="Delete Document"
                    variant="outline"
                    size="xs"
                    onClick={() => mutate()}
                    isLoading={isLoading}
                  />
                )}
              </Tooltip>
            }
          />
        </TD>
      )}
    </TR>
  )
}
