import { Lead } from 'models'
import type { FetchPresignedLinkProps } from '../fetchPresignedLink'
import { uploadToS3 } from '../utils'
import { postFileToDocuments } from './postFileToDocuments'

export type TPostFileUploadPayload = {
  category?: 'other'
  fileType: FetchPresignedLinkProps['fileType']
  files: File[]
  lead: Lead
  name: string
  sharedExternally?: boolean
  subAttachableId?: string
  subAttachableType?: string
}

const getExtension = (f: File) => f?.name.split('.').pop()

export const uploadFile = async (
  leadId: string,
  {
    subAttachableId,
    subAttachableType,
    fileType = 'document',
    files,
    name,
    category,
    sharedExternally
  }: TPostFileUploadPayload,
  options: {
    fileMetadata?: Omit<FetchPresignedLinkProps, 'mime'>
    onSuccess?: (data: any) => void
  } = {}
) => {
  const results = await uploadToS3(files, fileType, options.fileMetadata)

  const fileData = results.map((result, index) => ({
    name: name ? `${name}.${getExtension(files[index])}` : files[index].name,
    storageKey: result.storageKey,
    fileType: fileType || 'document'
  }))

  if (!options.onSuccess) {
    return postFileToDocuments({
      attachableId: leadId,
      attachableType: 'Lead',
      category,
      files: fileData,
      subAttachableId: subAttachableId,
      subAttachableType: subAttachableType,
      sharedExternally: sharedExternally
    })
  }

  return options?.onSuccess?.(results.map(result => result.storageKey))
}
