// @ts-strict
import { Model } from 'models/Model'
import { BBYSExtensionResponse } from 'schema'

export class BBYSExtension extends Model {
  createdAt?: string
  daysUntilExtensionExpiration?: string
  extensionRiskAdjustedValue?: string
  expiresAt?: string
  feeCharged?: string
  feePercentage?: string
  id?: string
  sentAt?: string
  signedAt?: string
  term?: string

  constructor(attributes?: Omit<BBYSExtension, keyof Model>) {
    super()
    Object.assign(this, attributes)
  }

  static create(bbysExtensionResponse: Potential<BBYSExtensionResponse>): Potential<BBYSExtension> {
    if (!bbysExtensionResponse) {
      return
    }
    return new BBYSExtension(bbysExtensionResponse)
  }
}
