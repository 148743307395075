import { Agent } from 'models'
import { AgentSchema, getHapiRequestParams } from 'schema'
import { getAgentsUrl } from 'services/getUrl'
import { deserializeAsync, getRequest } from 'utils'
import { agentDetailsForAgentMatchingLeads, agentDetailsShape } from './agentShapes'

export const agentResponseShape: AgentSchema = {
  agent: agentDetailsShape
}

export const agentResponseForAgentMatchingLeads: AgentSchema = {
  agent: agentDetailsForAgentMatchingLeads
}

export const fetchAgent = async (
  id: string,
  schema: AgentSchema = agentResponseShape
): Promise<Agent> => {
  const searchParams = getHapiRequestParams(schema)
  const url = getAgentsUrl({ id, searchParams })
  const response = await getRequest(url)
  return await deserializeAsync(response.data, {
    transform: Agent.create
  })
}
