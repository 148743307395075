// @ts-strict
import { useState } from 'react'
import { Box } from '@foundation/components'
import { Pagination, Section, Sections, Spinner, Table, TableResults } from 'components'
import { TableHeaderCell as TH, TableHeaderRow as TR } from 'components'
import { TableCell as Td, TableRow as Tr } from 'components'
import { usePayPerLeadSettingsController } from 'controllers'
import { Investor, InvestorPayPerLeadSetting } from 'models'
import { capitalize, formatDollarsAndCents } from 'utils'
import { IS_ZIP_CODE } from '../constants'
import styles from './LocationSettings.module.scss'
import { LocationSettingsUpdateButtonForm } from './LocationSettingsUpdateButtonForm'

type LocationSettingsTableProps = {
  active: boolean
  investor: Investor
}

type LocationSettingsRowProps = {
  locationSetting: InvestorPayPerLeadSetting
}

const slugToCityState = (slug: string) => {
  const parts = slug.split('-')
  const state = parts.pop()?.toUpperCase()
  const city = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ')

  return `${city}, ${state}`
}

export const LocationSettingsTableReadOnly = ({ active, investor }: LocationSettingsTableProps) => {
  const [page, setPage] = useState('1')
  const { pplSettingsQuery } = usePayPerLeadSettingsController(investor.id, 'Investor', {
    page: page,
    active: active ? 'true' : 'false'
  })

  const { data, isLoading, isError } = pplSettingsQuery

  if (isLoading) {
    return (
      <Box margin="$3">
        <Spinner size="xs" label="Loading Location Settings..." />
      </Box>
    )
  } else if (isError || !data) {
    return <div>Error loading location settings</div>
  }

  const { pplProviderSettings, totalCount } = data

  return (
    <>
      <Sections>
        <Section>
          <b style={{ paddingRight: '8px' }}>
            {' '}
            {active ? '' : 'Inactive'} Location Settings ({totalCount})
          </b>
        </Section>
        {active && (
          <Section>
            <LocationSettingsUpdateButtonForm investor={investor} />
          </Section>
        )}
      </Sections>
      <Table wrapperClassName={styles.table} data-testid="location-settings-table">
        <TR>
          <TH>Location</TH>
          <TH>User type</TH>
          <TH>Price</TH>
        </TR>
        <TableResults type="Pay-Per-Lead Location Settings" colSpan={2}>
          {pplProviderSettings.map((locationSetting: InvestorPayPerLeadSetting, index: number) => (
            <LocationSettingsRow key={index} locationSetting={locationSetting} />
          ))}
        </TableResults>
      </Table>
      {active && (
        <Pagination
          totalPages={Math.ceil(totalCount / 25)}
          currentPage={page}
          onChange={val => setPage(val)}
        />
      )}
    </>
  )
}

const LocationSettingsRow = ({ locationSetting }: LocationSettingsRowProps) => {
  const locationLabel = IS_ZIP_CODE(locationSetting.location!)
    ? locationSetting.location
    : slugToCityState(locationSetting.location!)

  return (
    <Tr data-testid="location-setting-replace-me">
      <Td>{locationLabel}</Td>
      <Td>{capitalize(locationSetting.userType || '')}</Td>
      <Td>{formatDollarsAndCents(Number(locationSetting.price) / 100)}</Td>
    </Tr>
  )
}
