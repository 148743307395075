// @ts-strict

import { useMutation, useQuery, useQueryClient } from 'hooks'
import { TMarketplaceProgramSlug } from 'lookups'
import { Agent } from 'models'
import { MarketplaceProgramAgentEnrollStatus } from 'models/MarketplaceProgramAgent/utils'
import { AgentSchema } from 'schema'
import {
  fetchAgent,
  patchAgentServiceEnrollment,
  postAgentUser,
  PostAgentUserPayload,
  putAgent,
  putAgentVisibility
} from 'services'
import { agentDetailsShape } from 'services/Agent/agentShapes'

const agentResponseShape: AgentSchema = {
  agent: agentDetailsShape
}

export const useAgentController = (
  agentId: string,
  enabled = false,
  schema: AgentSchema = agentResponseShape
) => {
  const queryCache = useQueryClient()

  const defaultCallback = {
    onSuccess: () => {
      queryCache.invalidateQueries(['agents', agentId])
      queryCache.invalidateQueries(['leads'])
    }
  }

  const { data, isLoading, isError } = useQuery(
    ['agents', agentId, schema],
    () => fetchAgent(agentId, schema),
    { enabled }
  )

  const agent = data ?? new Agent({ id: agentId })

  const updateAgentServiceEnrollment = useMutation(
    (payload: {
      enrollmentStatus: MarketplaceProgramAgentEnrollStatus
      notes?: string
      program: TMarketplaceProgramSlug
    }) =>
      patchAgentServiceEnrollment({
        id: agentId,
        payload
      }),
    defaultCallback
  )

  const updateAgent = useMutation((payload: any) => putAgent(agentId, payload), defaultCallback)

  const updateAgentVisibility = useMutation(
    (payload: any) => putAgentVisibility(agentId, payload),
    defaultCallback
  )

  const createAgentUser = useMutation(
    (payload: PostAgentUserPayload) => postAgentUser(agentId, payload),
    defaultCallback
  )

  return {
    agent,
    updateAgent,
    updateAgentVisibility,
    createAgentUser,
    updateAgentServiceEnrollment,
    isLoading,
    isError
  }
}
