import { Option } from 'classes'
import { BuyerLead, SellerLead } from 'models'

export type Props = {
  lead: BuyerLead | SellerLead
}

export const DISPOSITION_KEYS = {
  newOrClaimed: 'newOrClaimed',
  preIntro: 'preIntro',
  normalIntro: 'normalIntro',
  other: 'other',
  followUp: 'followUp',
  introPending: 'introPending',
  introduced: 'introduced',
  agentLeftVM: 'agentLeftVM',
  connected: 'connected',
  meetingScheduled: 'meetingScheduled',
  metInPerson: 'metInPerson',
  miscDispositionStages: 'miscDispositionStages',
  comingSoon: 'comingSoon',
  listing: 'listing',
  makingOffer: 'makingOffer',
  inEscrow: 'inEscrow',
  closed: 'closed',
  closedBooked: 'closedBooked',
  closedPaid: 'closedPaid',
  failed: 'failed',
  inContract: 'inContract',
  removed: 'removed'
} as const

export type DispositionSectionStages = keyof typeof DISPOSITION_KEYS

export type DispositionInfo = {
  canBeChosen: boolean
  key: DispositionSectionStages
  nextStages: DispositionSectionStages[]
  options: Option[]
  sectionTitle?: string
}

export type DispositionsByStage = DispositionInfo[]

export type BaseFormPayload = {
  agentOnlyIntroReasons?: string
  aiBotExitFlag?: string
  assistedIntroEligible?: string
  disposition: DispositionSectionStages | string
  followUpDate?: string
  investorOnlyIntroReasons?: string
  note?: string
  occurredAt?: string
  test?: boolean
}

export type BlindIntroFormPayload = {
  qualificationFakeEmail?: boolean
  qualificationIncompleteName?: boolean
  qualificationIncorrectAddress?: boolean
  qualificationIncorrectVoicemail?: boolean
  qualificationIsAnAgent?: boolean
  qualificationNotAProperHouse?: boolean
  qualificationPreviousContract?: boolean
  qualificationVerifiedPhone?: boolean
}

export type FormPayload = BaseFormPayload & BlindIntroFormPayload
