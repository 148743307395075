// @ts-strict
import { useEffect, useState } from 'react'
import {
  DisplayReadOnlyValue,
  FormField,
  If,
  InlineEditMoneyInput,
  InlineEditSelect,
  Section,
  Sections
} from 'components'
import {
  agentMatchingCallReviewBuyingProcess,
  agentMatchingCallReviewIntroType,
  agentMatchingCallReviewPreapproved,
  agentMatchingCallReviewQualificationMethod,
  agentMatchingCallReviewWhy,
  agentMatchingLeadTimelineOptions,
  yesOrNoForJsonOptions,
  yesOrNoOptions
} from 'lookups'
import {
  BuyerLead,
  isBuyerLead,
  isMortgageLead,
  isSellerLead,
  MortgageLead,
  SellerLead
} from 'models'
import { dataOrDash, stringifyValue } from 'utils'

type Props = {
  investorIntroFieldsActive?: boolean
  isDisposition?: boolean
  lead: BuyerLead | SellerLead | MortgageLead
  requiredFields?: Record<string, boolean>
  updateCallReview: (key: string, value: string) => void
  updateField: (value: Record<string, string | number | Record<string, string>>) => void
}

export const AgentMatchingLeadClientScreenSection = ({
  lead,
  updateCallReview,
  updateField,
  requiredFields = {},
  isDisposition = false,
  investorIntroFieldsActive = false
}: Props) => {
  const [lockIntroType, setLockIntroType] = useState(lead?.autoIntroed)

  useEffect(() => {
    setLockIntroType(lead?.autoIntroed || false)
  }, [lead?.autoIntroed])

  const primaryResidence =
    lead.callReview?.primaryResidence !== undefined
      ? lead.callReview?.primaryResidence
      : lead.callReview?.othersideInfo?.primaryResidence
  const isBuyerOrMortgageLead = isBuyerLead(lead) || isMortgageLead(lead)

  return (
    <Sections title="Client Screen">
      <Section className="client-screen">
        <FormField
          title={`Why ${isBuyerOrMortgageLead ? 'Buying' : 'Selling'}?`}
          required={requiredFields.why}
          inline
        >
          <InlineEditSelect
            onChange={(val: string) => {
              updateCallReview('why', val)
            }}
            options={agentMatchingCallReviewWhy}
            value={dataOrDash(lead?.callReview?.why)}
            isError={isBuyerLead(lead) && !lead?.callReview?.why}
          />
        </FormField>
        <If test={isMortgageLead(lead)}>
          <FormField title="Buying and Selling?" inline>
            <InlineEditSelect
              onChange={(val: string) => {
                updateCallReview('interestedInOtherside', val)
              }}
              options={yesOrNoForJsonOptions}
              value={dataOrDash(lead?.callReview?.interestedInOtherside)}
            />
          </FormField>
        </If>
        <If test={!isMortgageLead(lead)}>
          <FormField title="Has Agent?" required={requiredFields.alreadyHasAgent} inline>
            <InlineEditSelect
              onChange={(value: string) => updateField({ alreadyHasAgent: value })}
              options={yesOrNoOptions}
              value={stringifyValue(lead.alreadyHasAgent)}
              isError={
                isBuyerLead(lead) &&
                (lead.alreadyHasAgent === null || lead.alreadyHasAgent === undefined)
              }
            />
          </FormField>
        </If>
        <If test={!isMortgageLead(lead)}>
          <FormField
            title={`${isBuyerLead(lead) ? 'Selling' : 'Buying'} Too?`}
            required={requiredFields.interestedInOtherside}
            inline
          >
            <InlineEditSelect
              onChange={(val: string) => {
                updateCallReview('interestedInOtherside', val)
              }}
              options={yesOrNoForJsonOptions}
              value={dataOrDash(lead?.callReview?.interestedInOtherside)}
              isError={isBuyerLead(lead) && !lead?.callReview?.interestedInOtherside}
            />
          </FormField>
        </If>
        <If test={isBuyerOrMortgageLead}>
          <FormField title="Seller Address" required={requiredFields.sellerAddress} inline>
            {lead?.callReview?.othersideInfo?.fullAddress}
          </FormField>
        </If>
        <If test={isBuyerOrMortgageLead}>
          <FormField title="Primary Residence" required={requiredFields.primaryResidence} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('primaryResidence', value)}
              options={yesOrNoOptions}
              value={dataOrDash(primaryResidence)}
            />
          </FormField>
        </If>
      </Section>
      <Section className="client-screen">
        <If test={isSellerLead(lead)}>
          <FormField title="Price" inline>
            <InlineEditMoneyInput
              onBlur={(val: string) => {
                updateField({ price: val })
              }}
              value={dataOrDash(lead?.price)}
            />
          </FormField>
        </If>
        <If test={isSellerLead(lead) || isMortgageLead(lead)}>
          <FormField title="Currently Listed?" required={requiredFields.homeListed} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('homeListed', value)}
              options={yesOrNoForJsonOptions}
              value={stringifyValue(lead?.callReview?.homeListed)}
            />
          </FormField>
        </If>
        <If test={isBuyerOrMortgageLead}>
          <FormField
            title="Where in the buying process?"
            required={requiredFields.buyerProgress}
            inline
          >
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('buyerProgress', value)}
              options={agentMatchingCallReviewBuyingProcess}
              value={dataOrDash(lead?.callReview?.buyerProgress)}
              isError={isBuyerLead(lead) && !lead?.callReview?.buyerProgress}
            />
          </FormField>
        </If>
        <If test={isBuyerOrMortgageLead}>
          <FormField
            title="Where in the financing process?"
            required={requiredFields.preapproved}
            inline
          >
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('preapproved', value)}
              options={agentMatchingCallReviewPreapproved}
              value={dataOrDash(lead?.callReview?.preapproved)}
              isError={isBuyerLead(lead) && !lead?.callReview?.preapproved}
            />
          </FormField>
        </If>
        <FormField title="Timeline" inline>
          <InlineEditSelect
            onChange={(val: string) => {
              updateField({ timeline: val })
            }}
            options={agentMatchingLeadTimelineOptions}
            value={
              agentMatchingLeadTimelineOptions?.find(opt => opt.name === lead?.humanTimeline)?.value
            }
          />
        </FormField>
        <If test={!isSellerLead(lead)}>
          <FormField title={isSellerLead(lead) ? 'Price' : 'Purchase Price'} inline>
            <InlineEditMoneyInput
              onBlur={(val: string) => {
                updateField({ price: val })
              }}
              value={dataOrDash(lead?.price)}
            />
          </FormField>
        </If>
        <If test={isBuyerOrMortgageLead}>
          <FormField title="Need Loan to Buy?" required={requiredFields.interestedInLoan} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('interestedInLoan', value)}
              options={yesOrNoOptions}
              value={dataOrDash(lead?.callReview?.interestedInLoan)}
              isError={
                isBuyerLead(lead) &&
                (lead?.callReview?.interestedInLoan === null ||
                  lead?.callReview?.interestedInLoan === undefined)
              }
            />
          </FormField>
        </If>
        <FormField
          title="Qualification Method"
          required={requiredFields.qualificationMethod}
          inline
        >
          <InlineEditSelect
            onChange={(value: string) => updateCallReview('qualificationMethod', value)}
            options={agentMatchingCallReviewQualificationMethod}
            value={dataOrDash(lead?.callReview?.qualificationMethod)}
            isError={isBuyerLead(lead) && !lead?.callReview?.qualificationMethod}
          />
        </FormField>
        <If test={isSellerLead(lead) && (!isDisposition || !investorIntroFieldsActive)}>
          <FormField title="Intro Type" required={requiredFields.clientInterestedIn} inline>
            <InlineEditSelect
              onChange={(value: string) => updateCallReview('clientInterestedIn', value)}
              options={agentMatchingCallReviewIntroType}
              value={dataOrDash(lead?.callReview?.clientInterestedIn)}
              readOnly={lockIntroType}
            />
          </FormField>
        </If>
        <If test={lead?.callReview?.assistedIntroEligible}>
          <FormField title="Assisted Intro Eligible?" inline>
            <DisplayReadOnlyValue value={dataOrDash(lead?.callReview?.assistedIntroEligible)} />
          </FormField>
        </If>
      </Section>
    </Sections>
  )
}
