import { getBBYSLoansUrl } from 'services/getUrl'
import { deleteRequest } from 'utils'
interface DeleteBBYSLoanParams {
  bbysLeadId: string
  id: string
}

export const deleteBBYSLoan = ({ id, bbysLeadId }: DeleteBBYSLoanParams): Promise<any> => {
  return deleteRequest(`${getBBYSLoansUrl({ id })}?bbys_lead_id=${bbysLeadId}`)
}
