import { getFilesUrl } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

type TPayload = {
  attachableId: string
  attachableType: string
  category?: string
  files: { fileType: string; name: string; storageKey: string }[]
  sharedExternally?: boolean
  subAttachableId?: string
  subAttachableType?: string
}

export const postFileToDocuments = (payload: TPayload) =>
  postRequest(getFilesUrl(), snakeCaseKeys(payload))
