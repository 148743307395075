import {
  FormField,
  InlineEditBoolean,
  InlineEditDollarsAndCentsInput,
  InlineEditSelect,
  InlineEditTextInput,
  Section,
  Sections
} from 'components'
import { InlineEditDateInput } from 'components/InlineEdit/InlineEditDateInput/InlineEditDateInput'
import { useFeatureFlags } from 'hooks'
import { bbysLeadWarehouseOptions, yesOrNoOptions } from 'lookups'
import { cx, dataOrDash, dateDiffInDays, formatDate } from 'utils'
import styles from '../../../../@foundation/components/Table/Table.module.scss'
import { CCBBYSLeadDetailsProps } from '../CCBBYSLeadDetails'
import { getBBYSLeadInputProps, getBBYSLoanInputProps } from './utils'

type Props = Pick<
  CCBBYSLeadDetailsProps,
  'lead' | 'updateLead' | 'updateBBYSLoan' | 'updateBBYSLead' | 'createBBYSLoan'
>

export const EquityUnlockInformationSection = ({
  lead,
  createBBYSLoan,
  updateBBYSLoan,
  updateBBYSLead,
  updateLead
}: Props) => {
  const bbysLead = lead.bbysProviderLead.bbysLead
  const { getProps } = getBBYSLeadInputProps(bbysLead, updateBBYSLead)

  const hasOriginalLoan = !!bbysLead?.originalBBYSLoan?.id
  const { getOriginalLoanProps } = getBBYSLoanInputProps(
    bbysLead,
    hasOriginalLoan ? updateBBYSLoan : createBBYSLoan
  )
  const today = formatDate(new Date(), 'yyyy-MM-dd')
  const {
    bbysMaintenanceReserve,
    payoffDemandUpdates,
    equityBoostQuestionnaire,
    repaperExtensionTracking
  } = useFeatureFlags([
    'bbys-maintenance-reserve',
    'payoff-demand-updates',
    'equity-boost-questionnaire',
    'repaper-extension-tracking'
  ])

  const euFundingDateDiffInDays = dateDiffInDays(today, bbysLead?.loanFundingDate)
  // Add plus one day to include loan funding date
  const daysSinceEUFundingDate =
    euFundingDateDiffInDays >= 0 ? String(euFundingDateDiffInDays + 1).concat(' days ago') : null

  const irCoeDateDiffInDays = dateDiffInDays(
    today,
    bbysLead?.incomingResidencedExpectedCloseEscrowDate
  )
  // Add plus one day to include coe date
  const daysSinceIrCoeDateMessage =
    irCoeDateDiffInDays >= 0 ? String(irCoeDateDiffInDays + 1).concat(' days ago') : null

  return (
    <Sections title="Equity Unlock Information">
      <Section>
        <FormField
          title="Days since EU funding"
          inline
          valueWrapperClassName={cx(
            styles.daysSinceField,
            euFundingDateDiffInDays >= 60 && euFundingDateDiffInDays <= 89 ? styles.yellow : '',
            euFundingDateDiffInDays >= 90 && euFundingDateDiffInDays <= 120 ? styles.orange : '',
            euFundingDateDiffInDays > 120 ? styles.red : ''
          )}
        >
          {dataOrDash(daysSinceEUFundingDate)}
        </FormField>
        <FormField
          title="Days since IR COE"
          inline
          valueWrapperClassName={cx(
            styles.daysSinceField,
            irCoeDateDiffInDays >= 60 && irCoeDateDiffInDays <= 89 ? styles.yellow : '',
            irCoeDateDiffInDays >= 90 && irCoeDateDiffInDays <= 120 ? styles.orange : '',
            irCoeDateDiffInDays > 120 ? styles.red : ''
          )}
        >
          {dataOrDash(daysSinceIrCoeDateMessage)}
        </FormField>
        <FormField title="Buy Before You Sell loan amount" inline>
          <InlineEditDollarsAndCentsInput {...getProps('buyBeforeYouSellLoanAmount')} readOnly />
        </FormField>
        <FormField title="Equity Unlock loan funding amount" inline>
          <InlineEditDollarsAndCentsInput
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('loanFundingAmount')
              : getProps('equityUnlockLoanFundingAmount'))}
          />
        </FormField>
        <FormField title="Equity Unlock loan number" inline>
          <InlineEditTextInput
            maxLength={10}
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('loanNumber')
              : getProps('buyBeforeYouSellLoanNumber'))}
          />
        </FormField>
        <FormField title="Fundings Plus Date" inline>
          <InlineEditDateInput {...getProps('fundingsPlusDate')} readOnly />
        </FormField>
        {!equityBoostQuestionnaire.enabled && (
          <>
            <FormField title="Equity Boost Amount (approved)" inline>
              <InlineEditDollarsAndCentsInput {...getProps('equityBoostAmount')} />
            </FormField>
            <FormField title="Equity Boost Funded" inline>
              <InlineEditDollarsAndCentsInput {...getProps('equityBoostFunded')} />
            </FormField>
            <Section borderless title="Equity Boost Assets">
              <FormField title="Savings Accounts" inline>
                <InlineEditDollarsAndCentsInput
                  {...getProps('equityBoostCheckingSavingsAccount')}
                />
              </FormField>
              <FormField title="IRA & Brokerage Accounts" inline>
                <InlineEditDollarsAndCentsInput {...getProps('equityBoostIraBrokerageAccount')} />
              </FormField>
              <FormField title="401k" inline>
                <InlineEditDollarsAndCentsInput {...getProps('equityBoost401k')} />
              </FormField>
            </Section>
          </>
        )}
      </Section>
      <Section>
        {repaperExtensionTracking.enabled && (
          <FormField title="Equity Unlock Note Date" inline>
            <InlineEditDateInput {...getProps('equityUnlockNoteDate')} />
          </FormField>
        )}
        <FormField title="Equity Unlock IMAD number" inline>
          <InlineEditTextInput maxLength={25} {...getProps('equityUnlockTamNumber')} />
        </FormField>
        <FormField title="Fed Ex tracking number" inline>
          <InlineEditTextInput {...getProps('fedexTrackingNumber')} />
        </FormField>
        <FormField title="Warehouse facility" inline>
          <InlineEditSelect
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('warehouseFacility')
              : getProps('warehouseFacility'))}
            options={bbysLeadWarehouseOptions}
            addBlank
          />
        </FormField>
        <FormField title="Recording Fee" inline>
          <InlineEditDollarsAndCentsInput
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('recordingFee')
              : getProps('recordingFee'))}
          />
        </FormField>
        <FormField title="Notary Fee" inline>
          <InlineEditDollarsAndCentsInput
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('notaryFee')
              : getProps('notaryFee'))}
          />
        </FormField>
        <FormField title="Termination Fee" inline>
          <InlineEditDollarsAndCentsInput {...getProps('terminationFee')} />
        </FormField>
        {!payoffDemandUpdates.enabled && (
          <FormField title="Administration Fee" inline>
            <InlineEditDollarsAndCentsInput {...getProps('administrationFee')} />
          </FormField>
        )}
        {bbysMaintenanceReserve.enabled && (
          <>
            <FormField title="Maintenance Reserve" inline>
              <InlineEditBoolean
                options={yesOrNoOptions}
                {...getProps('maintenanceReserve')}
                addBlank={false}
              />
            </FormField>
            <FormField title="Maintenance Reserve Amount" inline>
              <InlineEditDollarsAndCentsInput {...getProps('maintenanceReserveAmount')} readOnly />
            </FormField>
          </>
        )}
        <FormField title="Documentary and Intangible Taxes" inline>
          <InlineEditDollarsAndCentsInput
            {...(repaperExtensionTracking.enabled
              ? getOriginalLoanProps('documentaryAndIntangibleTaxes')
              : getProps('documentaryAndIntangibleTaxes'))}
          />
        </FormField>
      </Section>
    </Sections>
  )
}
