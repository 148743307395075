/* eslint-disable sort-imports */
/* eslint-disable import/order */
import { useMemo, useState } from 'react'

import {
  CheckBoxArray,
  Column,
  Columns,
  EmployeesAutoComplete,
  MenuSection,
  Section,
  SectionFooter
} from 'components'

import { FiltersObj, useTableContext } from '@foundation/components/Table'

import {
  bbysStageOptions,
  bbysPartnerOptions,
  stateNameOptions,
  bbysDatesAndMilestonesOptions,
  bbysNotDatesAndMilestonesOptions,
  daysSinceEUFundingOrIrCoe,
  currentDrMlsStatus,
  stateNameItems,
  departingResidenceOptions
} from 'lookups'
import { useEmployeeUsers, useFeatureFlags } from 'hooks'

import styles from './styles.module.scss'
import { Option } from 'classes'
import { Button, Icon, MultiSelect } from '../../../../@foundation/components'
import { cx } from '../../../../utils'
import _ from 'lodash'

export const FiltersControl = () => {
  const { filterValues, onFilter, setIsFiltersOpen } = useTableContext()
  const { getUserName } = useEmployeeUsers()
  const [hasFiltersUpdated, setHasFiltersUpdated] = useState(false)
  const [multiSelectRootRef, setMultiSelectRootRef] = useState<HTMLDivElement>(null)

  const [filters, setFilters] = useState(filterValues)
  const { listingOpsLeadTracking } = useFeatureFlags(['listing-ops-lead-tracking'])

  const filtersObj = useMemo(() => {
    return Object.entries(filters).reduce((obj, [key, values]) => {
      if (values.length > 0) {
        return {
          ...obj,
          [key]: values.map(v => {
            switch (key as keyof typeof filters) {
              case 'stage':
                return bbysStageOptions.find(o => o.value === v)
              case 'partner_slug':
                return bbysPartnerOptions.find(o => o.value === v)
              case 'state_name':
                return stateNameOptions.find(o => o.value === v)
              case 'dates_and_milestones':
                return bbysDatesAndMilestonesOptions.find(o => o.value === v)
              case 'not_dates_and_milestones':
                return bbysNotDatesAndMilestonesOptions.find(o => o.value === v)
              case 'days_since_eu_funding':
                return daysSinceEUFundingOrIrCoe.find(o => o.value === v)
              case 'days_since_ir_coe':
                return daysSinceEUFundingOrIrCoe.find(o => o.value === v)
              case '_current_dr_mls_status':
                return currentDrMlsStatus.find(o => o.value === v)
              case 'departing_residence':
                return departingResidenceOptions.find(o => o.value === v)
              default: // lead_users.user_id
                return new Option({ name: getUserName(v), value: v })
            }
          }) as Option<string>[]
        }
      }
      return obj
    }, {} as FiltersObj)
  }, [filters, getUserName])

  const onChange = (key: keyof typeof filters, val: string[]) => {
    setFilters(prev => ({ ...prev, [key]: val }))
    setHasFiltersUpdated(true)
  }

  const handleClear = () => {
    setFilters({})
    setHasFiltersUpdated(true)
  }

  const handleApply = () => {
    onFilter(filtersObj)
    setHasFiltersUpdated(false)
    setIsFiltersOpen(false)
  }

  return (
    <Section className={styles.section}>
      <Columns className={styles.columns}>
        <Column className={styles.column}>
          <MenuSection title="Current Stage">
            <CheckBoxArray
              options={bbysStageOptions}
              value={filters.stage || []}
              onChange={val => onChange('stage', val)}
            />
          </MenuSection>
        </Column>

        <Column className={styles.column}>
          <MenuSection title="Partner">
            <CheckBoxArray
              options={bbysPartnerOptions}
              value={filters.partner_slug || []}
              onChange={val => onChange('partner_slug', val)}
            />
          </MenuSection>
        </Column>

        <Column className={styles.column}>
          <MenuSection title="Dates & Milestones">
            <CheckBoxArray
              options={bbysDatesAndMilestonesOptions}
              value={filters.dates_and_milestones || []}
              onChange={val => onChange('dates_and_milestones', val)}
            />
            <CheckBoxArray
              options={bbysNotDatesAndMilestonesOptions}
              value={filters.not_dates_and_milestones || []}
              onChange={val => onChange('not_dates_and_milestones', val)}
              className={styles.checkBoxArrayWithoutMargin}
            />
          </MenuSection>
        </Column>

        <Column className={styles.column}>
          <MenuSection title="Status">
            <CheckBoxArray
              options={currentDrMlsStatus}
              value={filters._current_dr_mls_status || []}
              onChange={val => onChange('_current_dr_mls_status', val)}
            />
          </MenuSection>
        </Column>

        <Column className={styles.column}>
          <MenuSection title="Days since EU funding">
            <CheckBoxArray
              options={daysSinceEUFundingOrIrCoe}
              value={filters.days_since_eu_funding || []}
              onChange={val => onChange('days_since_eu_funding', val)}
            />
          </MenuSection>

          <MenuSection title="Days since IR COE" className={styles.menuSection}>
            <CheckBoxArray
              options={daysSinceEUFundingOrIrCoe}
              value={filters.days_since_ir_coe || []}
              onChange={val => onChange('days_since_ir_coe', val)}
            />
          </MenuSection>
        </Column>

        {listingOpsLeadTracking.enabled && (
          <Column className={styles.column}>
            <MenuSection title="Departing Residence">
              <CheckBoxArray
                options={departingResidenceOptions}
                value={filters.departing_residence || []}
                onChange={val => onChange('departing_residence', val)}
              />
            </MenuSection>
          </Column>
        )}

        <Column className={cx(styles.column, 'resetOverflow')}>
          <MenuSection title="HomeLight member">
            <EmployeesAutoComplete
              value=""
              onSelect={val => {
                onChange(
                  'lead_users.user_id',
                  _.uniq([...(filters['lead_users.user_id'] || []), val?.id].filter(Boolean))
                )
              }}
              clearOnSelect
              onBlur={() => {}}
              onClear={() => onChange('lead_users.user_id', [])}
            />
            <div className={styles.tagsContainer}>
              {filters['lead_users.user_id'] &&
                filters['lead_users.user_id'].map(user => (
                  <span className={styles.tag}>
                    {getUserName(user)}
                    <button
                      className={styles.button}
                      onClick={() => {
                        onChange(
                          'lead_users.user_id',
                          filters['lead_users.user_id'].filter(u => u !== user)
                        )
                      }}
                    >
                      <Icon path="Close" size="xs" />
                    </button>
                  </span>
                ))}
            </div>
          </MenuSection>
        </Column>

        <Column className={cx(styles.column, 'resetOverflow')}>
          <MenuSection title="State">
            <div ref={setMultiSelectRootRef}>
              <MultiSelect
                triggerClassName={styles.multiSelectTrigger}
                values={filters?.state_name || []}
                items={stateNameItems.map(state => ({ label: state.name, value: state.value }))}
                onChange={val => onChange('state_name', val)}
                root={multiSelectRootRef}
              />
              <div className={styles.tagsContainer}>
                {filters.state_name &&
                  filters.state_name.map(state => (
                    <span className={styles.tag}>
                      {stateNameItems.find(s => s.value === state).name}
                      <button
                        className={styles.button}
                        onClick={() => {
                          onChange(
                            'state_name',
                            filters.state_name.filter(s => s !== state)
                          )
                        }}
                      >
                        <Icon path="Close" size="xs" />
                      </button>
                    </span>
                  ))}
              </div>
            </div>
          </MenuSection>
        </Column>
      </Columns>

      <SectionFooter className={styles.footer}>
        <Button variant="ghost" onClick={() => handleClear()}>
          Clear
        </Button>
        <Button onClick={() => handleApply()} isDisabled={!hasFiltersUpdated}>
          Apply
        </Button>
      </SectionFooter>
    </Section>
  )
}
