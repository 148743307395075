import { Box, Button, Flex, IconButton, Text } from '@foundation/components'
import { CheckBoxField, Section, Sections, TextInput } from 'components'
import { useDocumentsListContext } from '../contexts/documentsList'
import { Document, TTaskFormPayload } from '../utils'

type FieldProps = {
  document: Document
}

const DefaultField = ({ document }: FieldProps) => {
  const { update } = useDocumentsListContext()
  const { id, title, notes, enabled } = document

  return (
    <Box>
      <CheckBoxField
        title={title}
        checked={enabled}
        onChange={() => update(id, { enabled: !enabled, uploaded: false })}
      />
      {enabled && (
        <Box marginTop="$2" paddingLeft="$6">
          <TextInput onChange={val => update(id, { notes: val })} value={notes} />
        </Box>
      )}
    </Box>
  )
}

const CustomField = ({ document }: FieldProps) => {
  const { update, remove, errors } = useDocumentsListContext()
  const { id, title, notes, enabled } = document
  const error = errors[id]

  return (
    <Flex gap="$2" alignItems="flex-start">
      <Box flex="1">
        <Flex alignItems="flex-start">
          <CheckBoxField checked={enabled} onChange={() => update(id, { enabled: !enabled })} />
          <Box flex="1">
            <TextInput
              onChange={val => update(id, { title: val })}
              value={title}
              hasError={!!error}
            />
            {error && (
              <Box marginTop="$2">
                <Text size="xs" color="danger5">
                  {error}
                </Text>
              </Box>
            )}
          </Box>
        </Flex>
        {enabled && (
          <Box marginTop="$2" paddingLeft="$6">
            <TextInput onChange={val => update(id, { notes: val })} value={notes} />
          </Box>
        )}
      </Box>
      <IconButton
        title="Remove Document"
        icon="Close"
        variant="ghost"
        isRounded
        onClick={() => remove(id)}
      />
    </Flex>
  )
}

export type TTaskDocumentsProps = {
  payload: TTaskFormPayload
}

export const TaskDocuments = () => {
  const { documents, create } = useDocumentsListContext()

  return (
    <>
      <Sections>
        <Section>
          <Box marginBottom="$4">
            <Text size="sm" weight="bold">
              Select the documents you will like to request
            </Text>
          </Box>

          <Flex flexDirection="column" gap="$4">
            {documents.map(document => {
              if (document.default) {
                return <DefaultField key={document.id} document={document} />
              }
              return <CustomField key={document.id} document={document} />
            })}
          </Flex>
        </Section>
      </Sections>

      <Sections>
        <Section>
          <Button variant="ghost" color="primary" type="button" iconLeft="Add" onClick={create}>
            Add Document
          </Button>
        </Section>
      </Sections>
    </>
  )
}
