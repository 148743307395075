// @ts-strict
import { Button, useToastContext } from '@foundation/components'
import { ButtonForm, useButtonForm } from 'components'
import { useCurrentUserController, useFileUploadsController } from 'controllers'
import { useFeatureFlags } from 'hooks'
import { Lead } from 'models'
import { TFormValidation, validatePresence } from 'utils/validations'
import { LeadFileUploadsButtonFields } from './LeadFileUploadsButtonFields'

type TLeadFileUploadsButtonFormProps = {
  lead?: Lead
  multiple?: boolean
}

export type TLeadFileUploadsPayload = {
  category?: string
  files: File[]
  name: Potential<string>
  sharedExternally: boolean
  subAttachableId: Potential<string>
  subAttachableType: Potential<string>
}

export const LeadFileUploadsButtonForm = ({ lead, multiple }: TLeadFileUploadsButtonFormProps) => {
  const toast = useToastContext()
  const { currentUser } = useCurrentUserController()
  const { uploadFile } = useFileUploadsController(
    { leadId: lead?.id || '' },
    {
      fileMetadata: {
        attachableId: lead?.id,
        source: 'sales',
        uploaded_by: currentUser?.id
      }
    }
  )
  const { filesPermissioning } = useFeatureFlags(['files-permissioning'])

  const initialPayload: TLeadFileUploadsPayload = {
    files: [],
    name: '',
    subAttachableId: undefined,
    subAttachableType: undefined,
    category: filesPermissioning.enabled ? 'other' : undefined,
    sharedExternally: false
  }

  const formValidations: TFormValidation = {
    ...(!multiple ? { name: { label: 'Name', validations: [validatePresence] } } : {}),
    files: { label: 'Document', validations: [validatePresence] }
  }

  const { formProps, fieldsProps } = useButtonForm<TLeadFileUploadsPayload>({
    initialPayload,
    formValidations,
    mutation: uploadFile,
    callback: () => {
      toast({
        title: 'Documents successfully uploaded.',
        status: 'success'
      })
    }
  })

  const title = multiple ? 'Upload Files' : 'Upload File'

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={formProps.toggleShowForm}>
        {title}
      </Button>
      <ButtonForm buttonText={title} title={title} hideTriggerButton {...formProps}>
        <LeadFileUploadsButtonFields lead={lead} multiple={multiple} {...fieldsProps} />
      </ButtonForm>
    </>
  )
}
