// @ts-strict
import { Marker, Point } from 'classes'
import { Agent, BBYSProviderLead, LoanOfficer } from 'models'
import { BBYSExtension } from 'models/BBYSExtension'
import { BBYSLeadStageUpdate } from 'models/BBYSLeadStageUpdate/BBYSLeadStageUpdate'
import { BBYSLoan } from 'models/BBYSLoan'
import { SourcePartner } from 'models/SourcePartner'
import { TransactionCoorindator } from 'models/TransactionCoordinator'
import { BBYSLeadResponse } from 'schema'
import { formatPrice } from 'utils'

type TBBYSLeadConstructor = Omit<BBYSLead, 'toMarker' | 'toPoint'>

export class AutomatedChecks {
  lowEquityCheck?: boolean
  ruralPropertyCheck?: boolean
  listedDOMCheck?: boolean
  unrealisticValueCheck?: boolean
}

export class BBYSLead {
  id!: string
  actualLoanAmount?: string
  additionalClientNameOnTitle?: string
  additionalLiensOnProperty?: boolean
  administrationFee?: string
  agentEstimatedHomeValue?: string
  agentEstimatedLoanPayoff?: string
  agreementExpiresDate?: string
  agreementSentDate?: string
  approvalNumbersVerifiedDate?: string
  approvalType?: 'express' | 'instant' | 'light'
  approvalTypeIntake?: string
  approvedCombinedLoanValueRatio?: string
  automatedChecks?: AutomatedChecks
  bbysLeadStageUpdates?: BBYSLeadStageUpdate[]
  bbysLoans?: BBYSLoan[]
  bbysExtensions?: BBYSExtension[]
  buyBeforeYouSellLoanAmount?: string
  buyBeforeYouSellLoanFundingAmount?: string
  buyBeforeYouSellLoanNumber?: string
  cancellationFee?: boolean
  channel?: string
  combinedLoanToValueRatioIntake?: string
  contingencyRemovalOnly?: string
  createdAt?: string
  departingPropertyCondition?: number
  departingPropertyExternalLoanPayoff?: string
  departingPropertyFullAddress?: string
  departingPropertyGuaranteedPrice?: string
  departingPropertyHoaPresent?: boolean
  departingPropertyListingPrepFee?: string
  departingPropertyOwnershipExpenses?: string
  departingPropertyRepairCosts?: string
  departingPropertySellerAgent?: Agent
  departingPropertySellerColistAgent?: Agent
  departingPropertySellersAgentCommission?: string
  departingPropertySolarLoanBalance?: string
  departingPropertySolarPanelsPresent?: boolean
  departingPropertyTargetPurchaseCoeDate?: string
  departingPropertyTransactionCosts?: string
  departingPropertyUuid?: string
  departingPropertyValuation?: string
  departingResidenceBackupContractSentDate?: string
  departingResidenceBackupContractSignedDate?: string
  departingResidenceClientInContractDate?: string
  departingResidenceCurrentListPrice?: string
  departingResidenceExpectedCloseEscrowDate?: string
  departingResidenceFinalSalePrice?: string
  departingResidenceHeldInTrust?: boolean
  departingResidenceInEscrowDate?: string
  departingResidenceListedDate?: string
  departingResidenceOriginalListPrice?: string
  departingResidenceRequiredRepairsPreFunding?: string
  departingResidenceTitleCompany?: string
  departingResidenceTitleCompanyEmail?: string
  departingResidenceTitleCompanyPhone?: string
  departingResidenceYearBuilt?: string
  directPayoffRequestedAt?: string
  directPayoffSalePrice?: string
  disclosuresSentDate?: string
  division?: string
  documentCenterLink?: string
  documentaryAndIntangibleTaxes?: string
  dpActualLoanPayoff?: string
  dpBuyersAgentCommissionRate?: string
  dpEstimatedSalePrice?: string
  dpFirstPositionPayoffs?: string
  dpGpPercentageOfValuation?: string
  dpHelocBalance?: string
  dpLoanToValueRatio?: string
  dpMaxDownpaymentEquity?: string
  dpNonFirstPositionBalances?: string
  dpOtherLiensBalance?: string
  dpPropertyRepairHoldbackRate?: string
  dpSecondMortgageBalance?: string
  dpSellersAgentCommissionRate?: string
  dpSolarLeasePayoff?: string
  dpTargetClientClosingCosts?: string
  dpTargetClientClosingCostsRate?: string
  dpTargetNewHomePurchaseEquity?: string
  dpTargetTotalCosts?: string
  dpTargetTradeInFee?: string
  dpTargetTradeInFeeRate?: string
  dpTotalAgentCommission?: string
  dpUpfrontProceedsToClient?: string
  equityBoost?: string
  equityBoost401k?: string
  equityBoostAmount?: string
  equityBoostCheckingSavingsAccount?: string
  equityBoostFunded?: string
  equityBoostIraBrokerageAccount?: string
  equityBoostStage?: 'N/A' | 'Waiting for application' | 'In-review ' | 'Approved' | 'Denied'
  equityBoostTargetAmount?: string
  equityUnlockAvailableClient?: string
  equityUnlockDisclosuresSignedDate?: string
  equityUnlockHighEndRangeIntake?: string
  equityUnlockNoteDate?: string
  equityUnlockLoanFundingAmount?: string
  equityUnlockLowEndRangeIntake?: string
  equityUnlockTamNumber?: string
  estimatedMortagageBalanceIntake?: string
  estimatedMortgageBalanceSource?: string
  estimatedMortgageBalance?: string
  expectedDaysOnMarket?: string
  expectedLoanFundingDate?: string
  expectedProgramFeeRate?: string
  fedexTrackingNumber?: string
  feePercentage?: string
  feeValue?: string
  finalAgreementSentDate?: string
  finalAgreementSignedDate?: string
  finalApprovalType?: string
  floodInsuranceClearedDate?: string
  fundingsPlusDate?: string
  fundingsPlusType?: 'Cancellation' | 'BYOC' | 'BBYS Funding'
  hlcsAttached?: boolean
  hlcsNotAttachedReason?: string
  hlPurchasedDepartingResidenceDate?: string
  hlPurchasedDrValuationAmount?: string
  hlPurchasedDrValuationDate?: string
  hlPurchaseListedDate?: string
  hlPurchaseTitleCompany?: string
  hlPurchaseTitleCompanyAdditionalEmail?: string
  hlPurchaseTitleCompanyEmail?: string
  hlPurchaseTitleCompanyPhone?: string
  hlRefreshValuationAmount?: string
  hlRefreshValuationDate?: string
  hlSaleInEscrowDate?: string
  hlSoldDepartingResidenceDate?: string
  hlValuation?: string
  homelightDepartingResidencePurchaseDate?: string
  homelightPaidDate?: string
  homeOwnersInsuranceClearedDate?: string
  houseCanaryAvmValueIntake?: string
  incomingPropertyBuyerAgent?: Agent
  incomingPropertyFullAddress?: string
  incomingPropertyUuid?: string
  incomingResidencedExpectedCloseEscrowDate?: string
  incomingResidenceCounterOfferPresent?: string
  incomingResidenceDownPayment?: string
  incomingResidenceFinanceType?: string
  incomingResidenceFullyExecutedContract?: string
  incomingResidenceLoanAmount?: string
  incomingResidenceLoanContingencyEndDate?: string
  incomingResidenceMortgageType?: string
  incomingResidencePurchasePrice?: string
  incomingResidenceSellerInPossesion?: string
  incomingResidenceTitleCompany?: string
  incomingResidenceTitleCompanyEmail?: string
  incomingResidenceTitleCompanyPhone?: string
  incomingResidenceUnderContractAtIntake?: string
  inspectionCompleteDate?: string
  inspectionCost?: string
  inspectionOrderedDate?: string
  inspectionReviewedDate?: string
  inspectionScheduledDate?: string
  inspectionsRequired?: boolean
  instantEquityUnlockThresholdIntake?: string
  instantRiskAdjustedValueIntake?: string
  instantRiskAdjustmentPercentageIntake?: string
  internalNotes?: string
  investorName?: string
  lateListingFee?: string
  lenderEstimatedHomeValue?: string
  lienBalance1?: string
  lienBalance2?: string
  lienBalance3?: string
  lienBalance4?: string
  lienBalance5?: string
  lienType1?: string
  lienType2?: string
  lienType3?: string
  lienType4?: string
  lienType5?: string
  listingCallFollowUpDate?: string
  listingOpsNotes?: string
  listingPlanCallInviteDate?: string
  loanFundingDate?: string
  loanOfficer?: LoanOfficer
  loanPayoffValue?: string
  loanPayoffValueThresholdIntake?: string
  loanType?: 'original' | 'repaper'
  loManagedAllCash?: string
  maintenanceReserve?: boolean
  maintenanceReserveAmount?: string
  maximumEquityUnlockAmount?: string
  nameOfTrust?: string
  notaryFee?: string
  notesForHomelightOnIntake?: string
  originalBBYSLoan?: BBYSLoan
  payoffRequestedDate?: string
  payoffSentDate?: string
  photosRequired?: boolean
  primaryClientNameOnTitle?: string
  programExtensionDate?: string
  programFee?: string
  programFeeDiscount?: string
  programFeeSurcharge?: string
  programLength?: string
  propertyListed?: boolean
  providerLead?: BBYSProviderLead
  realEstatePortalLink?: string
  recordingFee?: string
  refreshedApprovalDate?: string
  riskAdjustedValue?: string
  riskAdjustmentPercentage?: string
  sourcePartner?: SourcePartner
  targetEquityUnlockAmount?: string
  targetEquityUnlockAmountIntake?: string
  terminationFee?: string
  totalSellingCostsPercentage?: string
  totalSellingCostsValue?: string
  transactionCoordinator?: TransactionCoorindator
  userValueToPropertyValueMeanIntake?: string
  warehouseFacility?: string

  constructor(bbysLeadAttributes: TBBYSLeadConstructor) {
    Object.assign(this, bbysLeadAttributes)
  }

  toMarker(color?: string) {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Marker({
          name: `${formatPrice(this.providerLead.lead.price, 'short')} ${
            this.providerLead.lead.fullAddress
          }`,
          description: this.providerLead.lead.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.providerLead?.lead?.latitude && this.providerLead.lead.longitude
      ? new Point({
          latitude: this.providerLead.lead.latitude,
          longitude: this.providerLead.lead.longitude
        })
      : undefined
  }

  static create(bbysLeadResponse: Potential<BBYSLeadResponse>): Potential<BBYSLead> {
    if (!bbysLeadResponse) {
      return
    }

    const departingPropertySellerAgent = Agent.create(bbysLeadResponse.departingPropertySellerAgent)
    const departingPropertySellerColistAgent = Agent.create(
      bbysLeadResponse.departingPropertySellerColistAgent
    )
    const incomingPropertyBuyerAgent = Agent.create(bbysLeadResponse.incomingPropertyBuyerAgent)
    const providerLead = BBYSProviderLead.create(bbysLeadResponse?.bbysProviderLead)
    const bbysLeadStageUpdates = (bbysLeadResponse.bbysLeadStageUpdates?.map(
      BBYSLeadStageUpdate.create
    ) || []) as BBYSLeadStageUpdate[]
    const bbysLoans = (bbysLeadResponse.bbysLoans?.map(BBYSLoan.create) || []) as BBYSLoan[]
    const originalBBYSLoan = bbysLoans?.filter(loan => loan.loanType === 'original')[0]
    const bbysExtensions = (bbysLeadResponse.bbysExtensions?.map(BBYSExtension.create) ||
      []) as BBYSExtension[]
    const sourcePartner = SourcePartner.create(bbysLeadResponse.sourcePartner)

    return new BBYSLead({
      ...bbysLeadResponse,
      departingPropertySellerAgent,
      departingPropertySellerColistAgent,
      incomingPropertyBuyerAgent,
      providerLead,
      bbysLeadStageUpdates,
      bbysExtensions,
      bbysLoans,
      originalBBYSLoan,
      sourcePartner
    })
  }
}
