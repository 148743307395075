/* eslint-disable typescript-sort-keys/interface */
/* eslint-disable import/order */

import { useEffect, useState } from 'react'

import { Flex } from '@foundation/components/Flex/styles'

import { CCBBYSLead } from 'models'
import { Icon, Section } from 'components'
import { cx, formatPhone, truncateString } from 'utils'

import styles from './ContactsLeadNavigation.module.scss'
import { Popover } from '@foundation/components'

// =================================================================================
// Info

const Info = ({
  title,
  value,
  enableCopy,
  isPhone
}: {
  title: string
  value?: string
  enableCopy?: boolean
  isPhone?: boolean
}) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(value)
      setCopySuccess(true)
    } catch (err) {
      setCopySuccess(false)
    }
  }

  const renderIcon = () => {
    if (copySuccess) {
      return <Icon.Check width={16} style={{ color: '#38BF8F' }} />
    }
    return <Icon.Copy onClick={copyToClipBoard} style={{ cursor: 'pointer', color: '#46B6FF' }} />
  }

  const renderValue = () => {
    if (value) {
      if (isPhone) {
        return formatPhone(value)
      }
      return value
    }
    return '---'
  }

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false)
      }, 2000)
    }
  }, [copySuccess])

  return (
    <div className={styles.infoContainer}>
      <p>{title}</p>
      <Flex gap="$2" alignItems="center">
        <p className={cx(styles.infoValue)}>{renderValue()}</p>
        <div className={styles.infoIconContainer}>{enableCopy && value && renderIcon()}</div>
      </Flex>
    </div>
  )
}

// =================================================================================
// Basic Info

type BasicInfoProps = {
  title: string
  name: string
  email: string
  phone: string
}

type BasicItemProps = Omit<BasicInfoProps, 'title'>

export const BasicInfo = ({ title, name, email, phone }: BasicInfoProps) => (
  <div className={styles.infoWrapper}>
    <Flex justifyContent="space-between" alignItems="center" marginBottom="$4">
      <p className={styles.itemPopupTitle}>{title}</p>
      <a href={email ? `mailto:${email}` : ''} className={styles.infoBtn}>
        Email
      </a>
    </Flex>
    <Info title="Name" value={name} />
    <Info title="Email" value={email} enableCopy />
    <Info title="Phone" value={phone} enableCopy isPhone />
  </div>
)

// =================================================================================
// Agent Info

type AgentInfoProps = {
  title: string
  name: string
  agentTeam: string
  email: string
  phone: string
  license: string
  brokerage: string
  noteLink: string
  agentLink: string
}

type AgentItemProps = Omit<AgentInfoProps, 'title'>

export const AgentInfo = ({
  title,
  noteLink,
  name,
  agentTeam,
  email,
  phone,
  license,
  brokerage,
  agentLink
}: AgentInfoProps) => (
  <div className={styles.infoWrapper}>
    <Flex justifyContent="space-between" alignItems="center" marginBottom="$4">
      <p className={styles.itemPopupTitle}>{title}</p>
      <Flex alignItems="center" gap="$3">
        <a href={email ? `mailto:${email}` : ''} className={styles.infoBtn}>
          Email
        </a>
        <a href={noteLink} className={styles.infoBtn}>
          Note
        </a>
      </Flex>
    </Flex>
    <Info title="Name" value={name} />
    <Info title="Agent Team" value={agentTeam} />
    <Info title="Email" value={email} enableCopy />
    <Info title="Phone" value={phone} enableCopy isPhone />
    <Info title="License" value={license} />
    <Info title="Brokerage" value={brokerage} />

    <a href={agentLink} className={styles.infoLink}>
      Go to Agent's page
    </a>
  </div>
)

// =================================================================================
// Item

type ItemProps = {
  title: string
  value?: string
  popup: React.ReactNode
}

const Item = ({ title, value, popup }: ItemProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <p>{title}</p>
      <div className={styles.itemContent}>
        <Popover.Root isHoverEnabled>
          <Popover.Trigger>
            <p className={styles.itemPopupTrigger}>{truncateString(value || '---', 20)}</p>
          </Popover.Trigger>
          <Popover.Content>{popup}</Popover.Content>
        </Popover.Root>
      </div>
    </Flex>
  )
}

// =================================================================================
// Client Item

export const ClientItem = ({
  name,
  email,
  phone,
  title = 'Primary Client'
}: BasicItemProps & { title?: string }) => (
  <Item
    title={title}
    value={name}
    popup={<BasicInfo title={title} name={name} email={email} phone={phone} />}
  />
)

// =================================================================================
// Loan Officer Item

export const LoanOfficerItem = ({ name, email, phone }: BasicItemProps) => (
  <Item
    title="Loan Officer"
    value={name}
    popup={<BasicInfo title="Loan Officer" name={name} email={email} phone={phone} />}
  />
)

// =================================================================================
// Home Consultant Item

const HomeConsultantItem = ({ name, email, phone }: BasicItemProps) => (
  <Item
    title="New Home Consultant"
    value={name}
    popup={<BasicInfo title="New Home Consultant" name={name} email={email} phone={phone} />}
  />
)

// =================================================================================
// Loan Officer Assistent Item

const LoanOfficerAssistantItem = ({ name, email, phone }: BasicItemProps) => {
  return (
    <Item
      title="Loan Officer Assistant"
      value={name}
      popup={<BasicInfo title="Loan Officer Assistant" name={name} email={email} phone={phone} />}
    />
  )
}

// =================================================================================
// Loan Officer Contacts Item

const LoanOfficerContacts = ({ name, email, phone }: BasicItemProps) => {
  return (
    <Item
      title="Loan Officer Contacts"
      value={name}
      popup={<BasicInfo title="Loan Officer Contacts" name={name} email={email} phone={phone} />}
    />
  )
}

// =================================================================================
// DR Agent Item

export const DRAgentItem = ({
  name,
  agentTeam,
  email,
  phone,
  license,
  brokerage,
  noteLink,
  agentLink
}: AgentItemProps) => (
  <Item
    title="DR Agent"
    value={name}
    popup={
      <AgentInfo
        title="DR Agent"
        noteLink={noteLink}
        name={name}
        agentTeam={agentTeam}
        email={email}
        phone={phone}
        license={license}
        brokerage={brokerage}
        agentLink={agentLink}
      />
    }
  />
)

// =================================================================================
// IR Agent Item

const IRAgentItem = ({
  name,
  agentTeam,
  email,
  phone,
  license,
  brokerage,
  noteLink,
  agentLink
}: AgentItemProps) => (
  <Item
    title="IR Agent"
    value={name}
    popup={
      <AgentInfo
        title="IR Agent"
        noteLink={noteLink}
        name={name}
        agentTeam={agentTeam}
        email={email}
        phone={phone}
        license={license}
        brokerage={brokerage}
        agentLink={agentLink}
      />
    }
  />
)

// =================================================================================
// Co-List Agent

const CoListAgentItem = ({
  name,
  agentTeam,
  email,
  phone,
  license,
  brokerage,
  noteLink,
  agentLink
}: AgentItemProps) => (
  <Item
    title="Co-Listing Agent"
    value={name}
    popup={
      <AgentInfo
        title="Co-Listing Agent"
        noteLink={noteLink}
        name={name}
        agentTeam={agentTeam}
        email={email}
        phone={phone}
        license={license}
        brokerage={brokerage}
        agentLink={agentLink}
      />
    }
  />
)

// =================================================================================
// Transaction Coordinator

const TransactionCoordinatorItem = ({ name, email, phone }: BasicItemProps) => {
  return (
    <Item
      title="Transaction Coordinator"
      value={name}
      popup={
        <BasicInfo title="TransactionItemCoordinator" name={name} email={email} phone={phone} />
      }
    />
  )
}

// =================================================================================
// DR Title Company Item

const DRTitleCompanyItem = ({ name, email, phone }: BasicItemProps) => (
  <Item
    title="DR Title  Company"
    value={name}
    popup={<BasicInfo title="DR Title  Company" name={name} email={email} phone={phone} />}
  />
)

// =================================================================================
// IR Title Company Item

const IRTitleCompanyItem = ({ name, email, phone }: BasicItemProps) => (
  <Item
    title="IR Title  Company"
    value={name}
    popup={<BasicInfo title="IR Title  Company" name={name} email={email} phone={phone} />}
  />
)

// =================================================================================
// Contacts Lead Navigation

type ContactsLeadNavigationProps = {
  lead: CCBBYSLead
}

export const ContactsLeadNavigation = ({ lead }: ContactsLeadNavigationProps) => {
  const {
    loanOfficer,
    departingPropertySellerAgent,
    departingPropertySellerColistAgent,
    incomingPropertyBuyerAgent,
    departingResidenceTitleCompany,
    departingResidenceTitleCompanyEmail,
    departingResidenceTitleCompanyPhone,
    incomingResidenceTitleCompany,
    incomingResidenceTitleCompanyEmail,
    incomingResidenceTitleCompanyPhone,
    sourcePartner
  } = lead.bbysLead

  const {
    newHomeConsultant,
    loanOfficerAssistant,
    loanOfficerAdditionalContacts,
    transactionCoordinator
  } = lead

  const hasContacts =
    !!loanOfficer ||
    !!departingPropertySellerAgent ||
    !!departingPropertySellerColistAgent ||
    !!incomingPropertyBuyerAgent ||
    !!transactionCoordinator ||
    !!departingResidenceTitleCompany ||
    !!incomingResidenceTitleCompany ||
    !!newHomeConsultant ||
    !!loanOfficerAssistant ||
    loanOfficerAdditionalContacts.length > 0

  if (!hasContacts) {
    return null
  }

  return (
    <Section title="Contacts" className={styles.contactsSection}>
      <Flex flexDirection="column" gap="$3" width="100%">
        <ClientItem name={lead.name} email={lead.email} phone={lead.phoneNumbers?.[0]?.number} />

        {lead.partnerName && (
          <ClientItem
            title="Additional Client"
            name={lead.partnerName}
            email={lead.emailAlt}
            phone={lead.phoneAlt}
          />
        )}

        {loanOfficer && (
          <LoanOfficerItem
            name={loanOfficer?.name}
            email={loanOfficer?.email}
            phone={loanOfficer?.phoneNumber?.number || loanOfficer?.phoneNumbers?.[0]?.number}
          />
        )}

        {loanOfficerAssistant && (
          <LoanOfficerAssistantItem
            name={loanOfficerAssistant?.user?.name}
            email={loanOfficerAssistant?.user?.email}
            phone={loanOfficerAssistant.user?.phoneNumbers?.[0]?.number}
          />
        )}

        {loanOfficerAdditionalContacts.length > 0 &&
          loanOfficerAdditionalContacts.map(contact => (
            <LoanOfficerContacts
              name={`${contact?.user?.firstName} ${contact?.user?.lastName}`}
              email={`${contact?.user?.email}`}
              phone={`${contact?.user?.phoneNumbers?.[0]?.number}`}
            />
          ))}

        {sourcePartner?.slug === 'lennar' && newHomeConsultant && (
          <HomeConsultantItem
            name={newHomeConsultant?.user?.name}
            email={newHomeConsultant?.user?.email}
            phone={newHomeConsultant?.user?.phoneNumbers[0]?.number}
          />
        )}

        {departingPropertySellerAgent && (
          <DRAgentItem
            name={departingPropertySellerAgent?.name}
            agentTeam={departingPropertySellerAgent?.agentTeamName}
            email={departingPropertySellerAgent?.email1}
            phone={
              departingPropertySellerAgent?.getPhoneMobile()?.formattedNumber ||
              departingPropertySellerAgent?.getPhoneOffice()?.number
            }
            license={departingPropertySellerAgent?.licenseNumber}
            brokerage={departingPropertySellerAgent?.agentTeamName}
            noteLink={departingPropertySellerAgent?.notes}
            agentLink={departingPropertySellerAgent?.getLink()?.pathname}
          />
        )}

        {departingPropertySellerColistAgent && (
          <CoListAgentItem
            name={departingPropertySellerColistAgent?.name}
            agentTeam={departingPropertySellerColistAgent?.agentTeamName}
            email={departingPropertySellerColistAgent?.email1}
            phone={
              departingPropertySellerColistAgent?.getPhoneMobile()?.formattedNumber ||
              departingPropertySellerColistAgent?.getPhoneOffice()?.number
            }
            license={departingPropertySellerColistAgent?.licenseNumber}
            brokerage={departingPropertySellerColistAgent?.agentTeamName}
            noteLink={departingPropertySellerColistAgent?.notes}
            agentLink={departingPropertySellerColistAgent?.getLink()?.pathname}
          />
        )}

        {incomingPropertyBuyerAgent && (
          <IRAgentItem
            name={incomingPropertyBuyerAgent.name}
            agentTeam={incomingPropertyBuyerAgent.agentTeamName}
            email={incomingPropertyBuyerAgent.email1}
            phone={incomingPropertyBuyerAgent.getPhoneOffice()?.number}
            license={incomingPropertyBuyerAgent.licenseNumber}
            brokerage={incomingPropertyBuyerAgent.agentTeamName}
            noteLink={incomingPropertyBuyerAgent.notes}
            agentLink={incomingPropertyBuyerAgent.getLink()?.pathname}
          />
        )}

        {transactionCoordinator && (
          <TransactionCoordinatorItem
            name={transactionCoordinator?.user?.name}
            email={transactionCoordinator?.user?.email}
            phone={transactionCoordinator.user?.phoneNumbers[0]?.number}
          />
        )}

        {departingResidenceTitleCompany && (
          <DRTitleCompanyItem
            name={departingResidenceTitleCompany}
            email={departingResidenceTitleCompanyEmail}
            phone={departingResidenceTitleCompanyPhone}
          />
        )}

        {incomingResidenceTitleCompany && (
          <IRTitleCompanyItem
            name={incomingResidenceTitleCompany}
            email={incomingResidenceTitleCompanyEmail}
            phone={incomingResidenceTitleCompanyPhone}
          />
        )}
      </Flex>
    </Section>
  )
}
