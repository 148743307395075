import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Button, useToastContext } from '@foundation/components'
import { Option } from 'classes'
import {
  ActionBar,
  ActionButton,
  ButtonForm,
  Contents,
  FormField,
  LoadingText,
  PaginationParams,
  Select
} from 'components'
import { EnvelopesTable } from 'content/Envelopes'
import {
  EnvelopesProvider,
  ListProvider,
  ParamsProvider,
  useCurrentUserContext,
  useEnvelopesContext,
  useLeadContext
} from 'contexts'
import { ENVELOPES_QUERY_KEY } from 'contexts/Envelopes/useEnvelopes'
import { useDocumentTitle, useFeatureFlags, useMutation } from 'hooks'
import { docusignListColumns } from 'lookups'
import { getApiRoot } from 'services/getUrl'
import { postRequest } from 'utils'
import { LeadDocumentsTabs } from '../LeadDocumentsTabs'

// Template Slugs

const TEMPLATE_SLUGS = {
  bbys_client_data_collection: 'Client Data Collection'
} as const

type TemplateSlug = typeof TEMPLATE_SLUGS
type TemplateSlugKey = keyof TemplateSlug
type TemplateSlugValue = TemplateSlug[TemplateSlugKey]

const templateSlugOptions = Object.entries(TEMPLATE_SLUGS).map(
  ([k, v]) => new Option({ name: v, value: k })
)

// Signing Configurations

type SigningConfigurationKey =
  | 'both_clients_sign'
  | 'primary_client_signs'
  | 'additional_client_signs'
  | 'loan_officer_signs_primary'
  | 'loan_officer_signs_additional'
  | 'loan_officer_signs_both'

const SIGNING_CONFIG_WITH_ADDITIONAL_CLIENT = {
  both_clients_sign: 'Both Clients Sign',
  primary_client_signs: 'Primary Client Signs',
  additional_client_signs: 'Additional Client Signs',
  loan_officer_signs_primary: 'Loan Officer Signs Primary',
  loan_officer_signs_additional: 'Loan Officer Signs Additional',
  loan_officer_signs_both: 'Loan Officer Signs Both'
} as const

const signingWithAdditionalClientOptions = Object.entries(
  SIGNING_CONFIG_WITH_ADDITIONAL_CLIENT
).map(([k, v]) => new Option({ name: v, value: k }))

const SIGNING_CONFIG_WITHOUT_ADDITIONAL_CLIENT = {
  primary_client_signs: 'Primary Client Signs',
  loan_officer_signs_primary: 'Loan Officer Signs Primary'
} as const

const signingWithoutAdditionalClientOptions = Object.entries(
  SIGNING_CONFIG_WITHOUT_ADDITIONAL_CLIENT
).map(([k, v]) => new Option({ name: v, value: k }))

function getSigningConfigurationOptions(hasAdditionalClient: boolean) {
  if (hasAdditionalClient) {
    return signingWithAdditionalClientOptions
  }
  return signingWithoutAdditionalClientOptions
}

// Create Envelopes Payload

type CreateEnvelopesPayload = {
  autoSend?: boolean
  signingConfiguration: SigningConfigurationKey
  templateSlug: TemplateSlugValue
}

const DocuSignModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedTemplateSlug, setSelectedTemplateSlug] = useState<TemplateSlugValue>(
    templateSlugOptions[0].value as TemplateSlugValue
  )
  const [selectedSigningConfiguration, setSelectedSigningConfiguration] = useState<
    SigningConfigurationKey | undefined
  >(undefined)

  const queryClient = useQueryClient()
  const { lead } = useLeadContext()
  const toast = useToastContext()

  const { mutate, isLoading } = useMutation(
    async ({ signingConfiguration, templateSlug, autoSend = true }: CreateEnvelopesPayload) => {
      const leadId = lead.id

      return postRequest(`${getApiRoot()}/esign-service/docusign/sales/leads/${leadId}/envelopes`, {
        template_slug: templateSlug,
        lead_id: leadId,
        signing_configuration: signingConfiguration,
        auto_send: autoSend
      })
    },
    {
      onSuccess: () => {
        setSelectedTemplateSlug(undefined)
        setSelectedSigningConfiguration(undefined)
        setShowModal(false)
        queryClient.invalidateQueries(ENVELOPES_QUERY_KEY)
        toast({
          title: 'Envelope created successfully',
          status: 'success'
        })
      },
      onError: error => {
        toast({
          title: `Oops! Something went wrong.`,
          status: 'error'
        })
      }
    }
  )
  const isFormValid = !!selectedSigningConfiguration && !!selectedTemplateSlug

  const onSubmit = () => {
    mutate({
      signingConfiguration: selectedSigningConfiguration,
      templateSlug: selectedTemplateSlug
    })
  }

  const signingConfigurationOptions = getSigningConfigurationOptions(
    !!lead.partnerName && !!lead.emailAlt
  )

  useEffect(() => {
    setSelectedSigningConfiguration(signingConfigurationOptions[0].value as SigningConfigurationKey)
  }, [lead.partnerName, signingConfigurationOptions])

  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={() => setShowModal(true)}>
        Generate Envelope
      </Button>
      <ButtonForm
        buttonText="Generate Envelope"
        title="Generate Envelope"
        hideTriggerButton
        showForm={showModal}
        toggleShowForm={() => !isLoading && setShowModal(!showModal)}
        handleClose={() => !isLoading && setShowModal(false)}
        handleHover={() => {}}
        showError={false}
        error=""
        errorMessage=""
        errors={{ slug: ['Ops!'], signingConfiguration: [] }}
        isFormValid={isFormValid}
        isLoading={isLoading}
        onSubmit={onSubmit}
        resetForm={() => {}}
      >
        <FormField title="Choose a Template" htmlFor="slug" required>
          <Select
            id="slug"
            options={templateSlugOptions}
            value={selectedTemplateSlug}
            onChange={val => setSelectedTemplateSlug(val as TemplateSlugValue)}
            addBlank
          />
        </FormField>

        <FormField title="Choose a Signing Configuration" htmlFor="signingConfiguration" required>
          <Select
            id="signingConfiguration"
            options={signingConfigurationOptions}
            value={selectedSigningConfiguration}
            onChange={val => setSelectedSigningConfiguration(val as SigningConfigurationKey)}
            addBlank
          />
        </FormField>
      </ButtonForm>
    </>
  )
}

const LeadDocusignTabContents = () => {
  const { envelopesQuery, oauthSignin } = useEnvelopesContext()
  const { currentUser } = useCurrentUserContext()

  const { leadSubmissionReduceEuLoanDocFlow } = useFeatureFlags([
    'lead-submission-reduce-eu-loan-doc-flow'
  ])

  if (envelopesQuery.isLoading) {
    return <LoadingText />
  }

  const actions = (
    <ActionBar>
      {!currentUser.docusignLoggedIn && (
        <ActionButton onClick={oauthSignin}>Sign In to DocuSign</ActionButton>
      )}
      {leadSubmissionReduceEuLoanDocFlow.enabled && <DocuSignModal />}
    </ActionBar>
  )

  return (
    <>
      <LeadDocumentsTabs />
      <Contents title="Docusign Envelopes" actions={actions}>
        <EnvelopesTable envelopes={envelopesQuery.data.envelopes} />
        <PaginationParams totalPages={envelopesQuery.data.pages} />
      </Contents>
    </>
  )
}

export const LeadDocusignTab = () => {
  const { lead } = useLeadContext()
  const { setTitle } = useDocumentTitle()
  useEffect(() => setTitle(`${lead.name} - Documents - Docusign Envelopes`), [lead, setTitle])

  return (
    <ParamsProvider>
      <EnvelopesProvider lead={lead}>
        <ListProvider columns={docusignListColumns}>
          <LeadDocusignTabContents />
        </ListProvider>
      </EnvelopesProvider>
    </ParamsProvider>
  )
}
