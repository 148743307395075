import { forwardRef } from 'react'
import { TextInput, TextInputProps } from '../TextInput'
import {
  integerMask,
  negativeIntegerMask,
  positiveIntegerMaskNoSeparator,
  under1000IntegerMask,
  under100IntegerMask
} from '../masks'

export const IntegerInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return <TextInput inputMask={integerMask} {...props} ref={ref} />
})

export const PositiveIntegerInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return <TextInput inputMask={positiveIntegerMaskNoSeparator} {...props} ref={ref} />
})

export const NegativeIntegerInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return <TextInput inputMask={negativeIntegerMask} {...props} ref={ref} />
})

export const Under100IntegerInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return <TextInput inputMask={under100IntegerMask} {...props} ref={ref} />
})

export const Under1000IntegerInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return <TextInput inputMask={under1000IntegerMask} {...props} ref={ref} />
})
