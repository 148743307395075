import { TColumnAttributes } from '../../Column'

const columns: Record<string, TColumnAttributes> = {
  name: {
    name: 'Name',
    value: 'name'
  },
  incomingResidence: {
    name: 'IR Address',
    value: 'incomingResidence'
  },
  createdAt: {
    name: 'Created At',
    value: 'createdAt'
  },
  sharedExternally: {
    name: 'Access',
    value: 'sharedExternally'
  },
  actions: {
    name: 'Actions',
    value: 'actions'
  }
}

export const fileUploadsColumnsSettings = [columns.name, columns.createdAt] as const

export const fileUploadsCashOfferColumnsSettings = [
  columns.name,
  columns.incomingResidence,
  columns.createdAt
] as const

export const fileUploadsBbysColumnsSettings = [
  columns.name,
  columns.sharedExternally,
  columns.createdAt,
  columns.actions
] as const

export type TFileUploadsColumnValue = (typeof fileUploadsCashOfferColumnsSettings)[number]['value']
