import { SearchParams } from '@types'
import { FileUploadsSchema, getHapiRequestParams } from 'schema'
import { presence, removeAttributesWithNullValues } from 'utils'

const sortParam = {
  name: 'name',
  createdAt: 'created_at'
}

export type TFileUploadsSearchParams = Pick<
  SearchParams<{ sortBy: keyof typeof sortParam }>,
  'page' | 'perPage' | 'sortBy' | 'sortDir'
> & {
  category?: string
  fileId?: string
  fileType?: string
  filterByName?: string
  leadId: string
}

const getFilters = ({ fileType, category, fileId }: Omit<TFileUploadsSearchParams, 'leadId'>) => {
  const filters = {}

  if (fileType) {
    filters['filter[file_type]'] = fileType
  }

  if (category) {
    filters['filter[category]'] = category
  }

  return filters
}

export const getFileUploadsSearchParams = ({
  page,
  perPage,
  sortBy,
  sortDir,
  leadId,
  filterByName,
  ...filters
}: TFileUploadsSearchParams) => {
  const sort = sortBy ? `${sortDir === 'desc' ? '-' : ''}${sortParam[sortBy]}` : sortParam.createdAt

  const fileUploadsListShape: FileUploadsSchema = {
    files: [
      'name',
      'createdAt',
      'category',
      'sharedExternally',
      {
        fileAttachment: [
          'downloadUrl',
          { 'subAttachable:cashOfferLead': ['incomingMasterProperty'] }
        ]
      }
    ]
  }

  return removeAttributesWithNullValues({
    ...getHapiRequestParams(fileUploadsListShape),
    sort,
    'filter[attachable_id]': leadId,
    'filter[attachable_type]': 'Lead',
    'filter[name]': filterByName,
    'page[number]': presence(page),
    'page[size]': presence(perPage),
    ...getFilters(filters)
  })
}
