import { getPresignedS3Url } from 'services/getUrl'
import { postRequest, snakeCaseKeys } from 'utils'

export type FetchPresignedLinkProps = {
  attachableId?: string
  documentType?: string
  fileType?: 'image' | 'document' | 'pdf_merge'
  file_name?: string
  filename?: string
  mime: string
  sharedExternally?: boolean
  source?: string
  uploaded_by?: string
}

export const fetchPresignedLink = async ({ mime, fileType, ...rest }: FetchPresignedLinkProps) => {
  const response = await postRequest(
    getPresignedS3Url(),
    snakeCaseKeys({
      mime,
      fileCount: 1,
      ...rest,
      ...(fileType ? { fileType } : {})
    })
  )

  return {
    ...response,
    data: response?.data?.[0]
  }
}
